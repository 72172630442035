import { useCallback, useEffect, useState } from "react";
import { apiSecure } from "../../components/api";
import { Loading, LoadingItem } from "../../components/Loading/Loading";
import "./Quotation.css";

import { RightOutlined, MenuOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslate } from "../../components/Translate";
import { useDispatch, useSelector } from "react-redux";
import config, { getEnvSettings } from "../../config/config";
import { AdvancedMarker, APIProvider, ControlPosition, InfoWindow, Map, MapControl, Marker, Pin, useAdvancedMarkerRef } from '@vis.gl/react-google-maps';
import { EditContactModal } from "../Project/ProjectInfo/EditContactModal";
import { BackBtn } from "../../components/BackBtn/BackBtn";
import { MapCustomBtns, RenderMapInfo } from "../../components/mapComponents";
import { useMediaQuery } from "react-responsive";
import { MapIcon } from "../../components/CustomIcons";
import AllActions from "../../store/actions";
import { getParsedDate, openMaps } from "../../components/globalFunctions";
import { AddQuotationContactModal } from "./AddQuotationContactModal";
import { AddContactModal } from "../Project/ProjectInfo/AddContactModal";
import { Modal } from "antd";



export const Quotation = ({ setToken }) => {
  const [isLoading, setisLoading] = useState(true);
  const dispatch = useDispatch();
  const { locale } = useTranslate();
  const navigate = useNavigate();
  const envConfig = getEnvSettings();

  let { projectId } = useParams();

  const [quotationData, setQuotationData] = useState({});


  const isMobile = useMediaQuery({ maxWidth: 500 });
  const location = useLocation();


  const API_KEY = process.env.REACT_APP_MAPS_API_KEY //config.devMapsKey


  const [isMapReady, setIsMapReady] = useState(false);
  const [coords, setCoords] = useState({ lat: 66.67, lng: 26.86 }); //Defaults to finland 



  const [selectedMarker, setSelectedMarker] = useState(null);
  const [infoWindowShown, setInfoWindowShown] = useState(false);

  const [calculationModalOpen, setCalculationModalOpen] = useState(false);

  const [initialFactoryDone, setInitialFactoryDone] = useState(0); // elements, notes, info
  const { tehtaat, selectedFactory } = useSelector((state) => state.currentUser.user);


  // Yhteystiedon lisäys 
  const [isAddContactModalOpen, setIsAddContactModalOpen] = useState(false);
  const [isAddQuotationContactModalOpen, setIsAddQuotationContactModalOpen] = useState(false);

  // Yhteystiedon muokkaus
  const [isEditContactModalOpen, setIsEditContactModalOpen] = useState(false);
  const [editContactData, setEditContactData] = useState({});


  //Projektin tiedoista otetaan tehdas ja asetetaan se tehtaaksi sovelluksessa
  useEffect(() => {
    console.log('projectRootData?.tiedot?.VastaanottanutTehdas', quotationData?.tiedot?.VastaanottanutTehdas)
    let initFactory = quotationData?.tiedot?.VastaanottanutTehdas
    if (initFactory && !initialFactoryDone) {
      setInitialFactoryDone(initFactory)
      console.log('TEHTAAN PÄIVITYS', initFactory)
      dispatch(AllActions.userActions.updateFactory(initFactory));
    }
  }, [quotationData]);

  //Jos tehdasta muutetaan manuaalisesti, mennään projektit sivulle
  useEffect(() => {
    console.log('Changed Factory:', selectedFactory, initialFactoryDone)
    if (initialFactoryDone && selectedFactory != initialFactoryDone) {
      navigate("/projects/")
    }
  }, [selectedFactory]);


  // Tietojen haku
  const getQuotation = () => {
    setisLoading(true);
    console.log("getQuotation");

    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}getQuotation`,
      data: {
        projectId: projectId,
      },
    };

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response);

        setQuotationData(response?.data?.data)
        setisLoading(false);
      })
      .catch((error) => {
        console.log("Error:", error);
        setisLoading(false);
      });
  };


  useEffect(() => {
    const isValidId = !isNaN(Number(projectId));
    if (!isValidId) {
      console.error("Virheellinen ID:", projectId);
      navigate("/error");
    } else {
      getQuotation();
    }
  }, [projectId]);




  useEffect(() => {
    console.log('quotationData Coords', quotationData?.tiedot)
    if (quotationData?.tiedot) {
      if (!quotationData?.tiedot?.lat || !quotationData?.tiedot?.lng) {
        setIsMapReady(true)
      } else {
        var newlat = parseFloat(quotationData?.tiedot?.lat.replace(/,/g, "."));
        var newlng = parseFloat(quotationData?.tiedot?.lng.replace(/,/g, "."));

        setCoords({ lat: newlat, lng: newlng, isDefault: false })
        setIsMapReady(true)
      }
    }
  }, [quotationData]);



  const askCreateQuotationCalc = () => {
    console.log('askCreateQuotationCalc');

    Modal.confirm({
      title: 'Haluatko tehdä uuden tarjouslaskennan',
      okText: 'Kyllä',
      cancelText: 'Ei',
      centered: true,
      onOk: () => {
        addQuotationCalc()
      },
      onCancel: () => {

      }
    });
  };

  const addQuotationCalc = () => {
    setisLoading(true);
    console.log("addQuotationCalc");

    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}addQuotationCalc`,
      data: {
        projectId: projectId
      },
    };

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response);
        let LaskentaId = response?.data?.LaskentaId
        console.log("LaskentaId", LaskentaId);

        setisLoading(false);
      })
      .catch((error) => {
        console.log("Error:", error);
        setisLoading(false);
      });
  };

  if (isLoading) {
    return <Loading />;
  }

  if (!quotationData.tiedot) {
    return (
      <div className="projects-nodata">
        <p className="projects-nodata-text font-dm-bold">Tarjousta ei löydy tai haussa ilmeni ongelmia!</p>
      </div>
    );
  }

  return (
    <div className="projectmain">

      <AddContactModal
        isOpen={isAddContactModalOpen}
        closeModal={() => {
          setIsAddContactModalOpen(false);
        }}
        quotationProjectId={quotationData?.tiedot?.Tyokohdenro} /* Tätä käytetään vain kun yhteistieto lisätään myös suoraaan projektille */
        refreshData={getQuotation}
      />

      <EditContactModal
        isOpen={isEditContactModalOpen}
        closeModal={() => {
          setIsEditContactModalOpen(false);
        }}
        quotationProjectId={quotationData?.tiedot?.Tyokohdenro} /* Tätä käytetään vain kun yhteistieto lisätään myös suoraaan projektille */
        data={editContactData}
        refreshData={getQuotation}
      />

      <AddQuotationContactModal
        isOpen={isAddQuotationContactModalOpen}
        closeModal={() => {
          setIsAddQuotationContactModalOpen(false);
        }}
        projectId={quotationData?.tiedot?.Tyokohdenro}
        refreshData={getQuotation}
        addedContacts={[]}
        openAddContact={() => {
          setIsAddContactModalOpen(true);
          setIsAddQuotationContactModalOpen(false);
        }}
      />

      <div className={`projectpage-content`}>
        <div className={`project-header-12 `}>
          <BackBtn style={{ marginBottom: 5 }} onClick={() => {
            if (location?.state?.from === "/contact") {
              navigate(-1);
            } else {
              navigate('/projects'); // Korvaa '/pääsivu' haluamallasi pääsivun URLilla
            }
          }} />

          {!isMobile ?
            <div className="project-header-smallrow">
              <p className="project-header-id font-dm-bold">
                {quotationData.tiedot.Tyokohdenro}
              </p>

              <p className="quotation-header-label font-dm-regular">
                Tarjouspyyntö
              </p>
            </div>
            : <></>}

          <h1 className="project-header font-itc">
            {quotationData.tiedot.Nimi}
          </h1>

          {quotationData.tiedot.JattoPVM &&
            <div className="quotation-header-end-item">
              <p className="label font-dm-regular">Erääntymispäivä</p>
              <p className="value font-dm-bold">{getParsedDate(quotationData.tiedot.JattoPVM)}</p>
            </div>
          }
        </div>



        <div className="quotation-info">
          <p className="quotation-info-title font-dm-bold">Tarjouspyyntö</p>

          <div className="quotation-info-box">
              <p className="quotation-info-box-header font-dm-bold">Kohteen sijainti</p>

              <div className="quotation-info-row">
                <div className="quotation-info-row-item">
                  <div className="quotation-info-row">
                    <p className="quotation-info-row-item-header font-dm-regular">Toimitusosoite</p>
                    {/* <div className="quotation-info-edit" onClick={() => {setIsEditAddressModalOpen(true); }}>
                        <EditOutlined />
                      </div> */}
                  </div>
                  <p className="quotation-info-row-item-value font-dm-bold">{quotationData?.tiedot?.Toimitusosoite1 ? quotationData?.tiedot?.Toimitusosoite1 : ''}</p>
                  <p className="quotation-info-row-item-value font-dm-bold">{quotationData?.tiedot?.Toimitusosoite2 ? quotationData?.tiedot?.Toimitusosoite2 : ''}</p>
                </div>

                <div className="quotation-info-row-item">
                  <div className="quotation-info-row">
                    <p className="quotation-info-row-item-header font-dm-regular">Oletustehdas kuljetukselle</p>
                    {/* <div className="quotation-info-edit" onClick={() => { setIsEditReceiverModalOpen(true); }}>
                        <EditOutlined />
                      </div> */}
                  </div>
                  <p className="quotation-info-row-item-value font-dm-bold">{quotationData?.tiedot?.OletustehdasKuljetukselle ? tehtaat[quotationData?.tiedot?.OletustehdasKuljetukselle]?.name : ''}</p>
                </div>


                <div className="quotation-info-row-item">
                  <div className="quotation-info-row">
                    <p className="quotation-info-row-item-header font-dm-regular">Kuljetusmatka</p>
                    {/* <div className="quotation-info-edit" onClick={() => { setIsEditReceiverModalOpen(true); }}>
                        <EditOutlined />
                      </div> */}
                  </div>
                  <p className="quotation-info-row-item-value font-dm-bold">{quotationData?.tiedot?.KuljetusmatkaArvio ? quotationData?.tiedot?.KuljetusmatkaArvio + ' km' : ''}</p>
                </div>
              </div>



              <div className="quotation-info-row-map">
                {isMapReady ?
                  <APIProvider apiKey={API_KEY}>
                    <Map
                      id={"map-custom-id"}
                      style={{ width: '100%', height: '100%' }}
                      defaultCenter={{ lat: coords?.lat, lng: coords?.lng }}
                      defaultZoom={coords?.isDefault ? 3 : 10}
                      gestureHandling={'greedy'}
                      disableDefaultUI={true}

                    >
                      <MapCustomBtns id={"map-custom-id"} />
                      {!coords?.isDefault &&
                        <MapControl position={ControlPosition.INLINE_END_BLOCK_END}>
                          <button className="quotation-info-row-map-btn font-dm-regular clickable" onClick={() => {
                            openMaps(coords)
                          }}>
                            <MapIcon className="quotation-info-row-map-btn-icon" />
                            Näytä kartalla</button>
                        </MapControl>
                      }
                      {!coords?.isDefault && <Marker position={{ lat: coords?.lat, lng: coords?.lng }} />}
                    </Map>
                  </APIProvider>
                  : null}
              </div>
          </div>

          {/* Kyselijät */}
          <div className="quotation-info-box" style={{marginTop: "1em"}}>
            <p className="quotation-info-box-header font-dm-bold">Kyselijät</p>

            <div className="quotation-info-box-row">
              <div style={{}}>
                {quotationData?.kyselijat?.length >= 1 && quotationData?.kyselijat?.map((contact) => {
                  console.log('contact', contact)



                  return (
                    <div className="project-info-contacts-item" key={contact?.YhteysNRO}>
                      <div className="project-info-row">
                        <p className={`project-info-contacts-item-header font-dm-bold`}>{contact?.Henkilo}</p>
                        <div className="project-info-edit project-info-marginleft" onClick={() => {
                          console.log('contact', contact);
                          setEditContactData(contact)
                          setIsEditContactModalOpen(true)
                        }}>
                          <EditOutlined />
                        </div>
                      </div>

                      <p className="font-dm-regular">{contact?.GSM}</p>
                      <p className="font-dm-regular">{contact?.Sahkoposti}</p>
                    </div>
                  )
                })}
              </div>

              <div className="quotation-info-box-add" onClick={() => {
                setIsAddQuotationContactModalOpen(true)
              }}>
                <PlusOutlined className="quotation-info-box-addIcon" />
              </div>
            </div>

          </div>
        </div>


        <div className="quotation-info">
          <p className="quotation-info-title font-dm-bold">Tarjouslaskenta</p>

          {quotationData?.laskennat?.length >= 1 && quotationData?.laskennat?.map((calculation) => {
            console.log('calculation', calculation)
            let calcId = calculation?.LaskentaId
            return (
              <Link to={`/quotation/${projectId}/calculation/${calcId}`} key={calcId} style={{textDecoration:'none'}} >
                <div className="quotation-calculation-item font-dm-bold">
                  <p className="label font-dm-bold">{calculation.Kuvaus}</p>
                </div>
              </Link>
            )
          })}

          <div className="quotation-info-box-add" style={{marginTop: 10}} onClick={() => {
            askCreateQuotationCalc()
          }}>
            <PlusOutlined className="quotation-info-box-addIcon" />
          </div>

        </div>
      </div>
    </div>
  );
};
