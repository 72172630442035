import { FC, useEffect, useRef, useState } from "react";
import { CellWrapper, useCellContext, useDoubleTouch } from "@silevis/reactgrid";

export const ElemHoleCell = ({ value:valueText, formula: initialText, round, onOpen, onFormulaChanged, style }) => {
  const ctx = useCellContext();
  const targetInputRef = useRef(null);
  const [isEditMode, setEditMode] = useState(false);
  const [currentValue, setCurrentValue] = useState(initialText || "");
  const escapePressedRef = useRef(false);
  const { handleDoubleTouch } = useDoubleTouch(ctx, setEditMode);

/*   useEffect(() => {
    setCurrentValue(initialText);
  }, [initialText]); */

  return (
    <CellWrapper
      onTouchEnd={handleDoubleTouch}
      onStringValueRequested={() => initialText}
      onStringValueReceived={(v) => onFormulaChanged?.(v)}
      style={style}
      onDoubleClick={() => {
        onOpen()
/*         if (ctx.isFocused) {
          setCurrentValue(initialText && initialText !== "0" ? initialText : "");
          setEditMode(true);
        } */
      }}
      onKeyDown={(e) => {
        console.log('onKeyDown', e.key)

        if (!isEditMode &&/^[a-zA-Z0-9=]$/.test(e.key)) {
          setCurrentValue("");
          setEditMode(true);
        } else if (!isEditMode && !ctx.isSelected && (e.key === "Enter" || e.key === "F2")) {
          e.stopPropagation();
          setCurrentValue(initialText && initialText !== "0" ? initialText : "");
          setEditMode(true);
        }


      }}
    >
      {isEditMode ? (
        <input
          className="rg-input"
          value={currentValue}
          onChange={(e) => setCurrentValue(e.currentTarget.value)}
          onBlur={(e) => {
            if (!escapePressedRef.current) {
              let value = e.currentTarget.value.replace(/,/g, ".");
              onFormulaChanged?.(value);            
            }
            setEditMode(false);
            if (escapePressedRef.current) {
              escapePressedRef.current = false;
            }
          }}
          onCut={(e) => e.stopPropagation()}
          onCopy={(e) => e.stopPropagation()}
          onPaste={(e) => e.stopPropagation()}
          onPointerDown={(e) => e.stopPropagation()}
          onKeyDown={(e) => {
            const controlKeys = ["Escape", "Enter", "Tab"];

            if (!controlKeys.includes(e.key)) {
              e.stopPropagation();
            }
            if (e.key === "Escape") {
              escapePressedRef.current = true;
              setEditMode(false);
            } else if (e.key === "Enter") {
              setEditMode(false);
            }
          }}
          autoFocus
          ref={targetInputRef}
        />
      ) : (

        !isNaN(valueText) ? valueText?.toFixed(round) : valueText
        
      )}
    </CellWrapper>
  );
};