import { ControlPosition, InfoWindow, MapControl, useMap } from '@vis.gl/react-google-maps';
import { FullscreenExitOutlined, FullscreenOutlined, GoldFilled, GoldOutlined, PictureOutlined } from "@ant-design/icons";
import { useEffect, useState } from 'react';


export const RenderMapInfo = ({ infoWindowShown, selectedMarker, handleClose }) => {
    const clickItem = (id) => {
        window.open(`${window.location.origin}/project/${id}`)
    }

    return (<>
        {infoWindowShown && selectedMarker && (
            <InfoWindow
                position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
                onClose={handleClose}
                headerContent={<div className="map-info-header">
                    <p className="font-dm-bold">{selectedMarker.isProject ? `${selectedMarker.id} ${selectedMarker.name}` : ''}{selectedMarker.isFactory ? 'Tehdas' : ''}</p>
                </div>}
            >
                {selectedMarker.isProject ? <>
                    {/*      <p className="map-info-data font-dm-medium">{selectedMarker.id} {selectedMarker.name}</p> */}
                    <p className="map-info-data font-dm-medium">Tila: {selectedMarker?.status}</p>

                    <button className="map-info-btn font-dm-medium clickable" onClick={() => {
                        clickItem(selectedMarker?.id)
                    }}>
                        Avaa työkohde
                    </button>
                </> : <></>}

                {selectedMarker.isFactory ? <>
                </> : <></>}
            </InfoWindow>
        )}

    </>)
}


export const MapCustomBtns = ({ id, showCluster = false, clusterActive, setClusterActive }) => {
    const map = useMap(id);

    const [isFullscreen, setIsFullscreen] = useState(false);




    function checkIsFullscreen() {
        const element = map.getDiv().firstChild

        return (
            (document.fullscreenElement ||
                document.webkitFullscreenElement ||
                document.mozFullScreenElement ||
                document.msFullscreenElement) == element
        );
    }

    function requestFullscreen() {
        const element = map.getDiv().firstChild

        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
            element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
        } else if (element.msRequestFullScreen) {
            element.msRequestFullScreen();
        }
    }

    function exitFullscreen() {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
    }

    function clickFullscreen() {
        if (checkIsFullscreen()) {
            exitFullscreen()
        } else {
            requestFullscreen()
        }
    }

    function clickMapType() {
        let currType = map.getMapTypeId()
        let newType = ''

        if (currType === 'roadmap') {
            newType = 'terrain'
        } else if (currType === 'terrain') {
            newType = 'hybrid'
        } else if (currType === 'hybrid') {
            newType = 'roadmap'
        }

        map.setMapTypeId(newType);
    }


    useEffect(() => {
        // Päivitetään isFullscreen, kun fullscreen-tila muuttuu
        function handleFullscreenChange() {
            setIsFullscreen(checkIsFullscreen());
        }

        // Kuunnellaan fullscreen-tilan muutoksia
        document.addEventListener('fullscreenchange', handleFullscreenChange);
        document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
        document.addEventListener('mozfullscreenchange', handleFullscreenChange);
        document.addEventListener('MSFullscreenChange', handleFullscreenChange);

        // Poistetaan kuuntelijat, kun komponentti unmountataan
        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
            document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
            document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
            document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
        };
    }, [])


    return (<>

        <MapControl position={ControlPosition.BLOCK_START_INLINE_END}>
            <div className="map-custom-fullscreen clickable" onClick={clickFullscreen}>
                {isFullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}

            </div>
        </MapControl>

        <MapControl position={ControlPosition.BLOCK_START_INLINE_START}>
            <div className="map-custom-maptype clickable" onClick={clickMapType}>
                <PictureOutlined />
            </div>
        </MapControl>

        {showCluster &&
            <MapControl position={ControlPosition.BLOCK_START_INLINE_START}>
                <div className="map-custom-maptype clickable" onClick={() => {
                    setClusterActive(!clusterActive)
                }}>
                    {clusterActive
                        ? <GoldFilled />
                        : <GoldOutlined />
                    }
                </div>
            </MapControl>
        }
    </>

    );
};