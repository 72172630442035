import { CloseOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";

import { Input, Modal, Button, DatePicker, Select, Checkbox, notification } from "antd";
import dayjs from "dayjs";
import { useTranslate } from "../../../components/Translate";
import { apiSecure } from "../../../components/api";
import { getEnvSettings } from "../../../config/config";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import excelTypesArr from './ExcelTypes.json';


const allTypes = excelTypesArr.map(itm => {
  const ids = {};
  if (itm.defaultId) {
    ids[itm.defaultId] = itm.taulut
  }
  return ids;
});
console.log('allTypes2', allTypes);


const CheckboxItem = ({ itemId, itemName, hidedColumns, setHidedColumns }) => {
  console.log('CheckboxItem', hidedColumns, itemName)

  return <Checkbox
    key={itemId}
    checked={hidedColumns?.includes(itemId)}
    onChange={(e) => {
      setHidedColumns(prev =>
        e.target.checked
          ? [...prev, itemId] // Lisää itemId jos checkbox on valittu
          : prev.filter(col => col !== itemId) // Poista itemId jos checkbox ei ole valittu
      );
    }}
  >
    {itemName}
  </Checkbox>
}


const DefaultInputItem = ({ label, value, setValue, maxLength }) => {
  return <div className="excel-settings-default-input">
    <p className="label font-dm-regular">{label}</p>
    <Input
      className="input"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      maxLength={maxLength}
    />
  </div>
}

export const ExcelSettingsModal = ({ isOpen, closeModal, groupId, groupData, materialColumns, refreshData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { locale } = useTranslate();
  const envConfig = getEnvSettings();
  const [defaultLabel, setDefaultLabel] = useState("");
  const [editData, setEditData] = useState({});

  // Laskentataulukon tyyppi, kertoo mitä tietoja näytetään milläkin taulukolla
  const typeId = groupData?.LaskentaTaulukko

  const [materialsList, setMaterialsList] = useState([]);

  const hideableCheckboxes = excelTypesArr.filter(
    (item) => item.hideableId && item.taulut.includes(typeId)
  );

  console.log('hideableCheckboxes', hideableCheckboxes)

  const defaultInputs = excelTypesArr.filter(
    (item) => item.defaultId && item.taulut.includes(typeId)
  );
  console.log('defaultInputs', defaultInputs)

  console.log('materialColumns', materialColumns)
  console.log('materialsList', materialsList)


  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result.map((item, index) => ({
      ...item,
      jarjestys: index + 1,
      isEdited: true
    }));
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const reordered = reorder(materialsList, result.source.index, result.destination.index);
    setMaterialsList(reordered);
  };

  const setMaterialValue = (key, newValue) => {
    setMaterialsList((prev) =>
      prev.map((item) =>
        item.key === key
          ? { ...item, OletusKaava: newValue, isEdited: true }
          : item
      )
    );
  };

  // Asetetaan muutettavat arvot stateen
  useEffect(() => {
    if (isOpen) {
      const initialEditData = {
        ...defaultInputs.reduce((acc, item) => {
          acc[item.defaultId] = groupData[item.defaultId]?? "";
          return acc;
        }, {}),
        ...hideableCheckboxes.reduce((acc, item) => {
          acc[item.hideableId] = groupData[item.hideableId] ?? false;
          return acc;
        }, {}),
      };
      setEditData(initialEditData);
      console.log('initialEditData', initialEditData)

      const nMaterialsList = materialColumns.map((itm, index) => ({
        key: `tarvike_${itm.TarvikkeetId}`,
        TarvikkeetId: itm.TarvikkeetId,
        name: itm.Koodi,
        OletusKaava: itm.OletusKaava,
        isEdited: true,
        jarjestys: index + 1,
      }));

      setMaterialsList(nMaterialsList);

    }
  }, [isOpen]);

  // Viedään muutettavat arvot backendiin
  const saveData = () => {
    console.log('saveData', editData)
    setIsLoading(true)

    let req_config = {
      method: "POST",
      url: `intra_updateExcelGroupSettings`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        groupId: groupId,
        editData: editData,
        materialsList: materialsList
      }
    };

    apiSecure(req_config)
      .then((response) => {
        if (!response || !response?.data?.status) {
          throw new Error(`Asetusten päivityksessä tapahtui virhe!. Status: ${response?.status}`);
        }
        setIsLoading(false)
        closeModal()
        refreshData()
        notification.open({
          message: "Asetukset päivitetty",
          placement: 'top',
          type: 'success'
        });
      })
      .catch((error) => {
        console.log("Error:", error);
        notification.open({
          message: "Asetusten päivityksessä tapahtui virhe! Päivitä sivu ja yritä uudelleen",
          placement: 'top',
          type: 'error'
        });
      })

  }


  return (
    <>
      <Modal
        open={isOpen}
        onCancel={() => closeModal()}
        title={`Taulukon asetukset`}
        centered={true}
        footer={[
          <Button key="submit" type="primary" color="red" onClick={() => {
            saveData()
          }}
            loading={isLoading}>
            Tallenna
          </Button>
        ]}
      >
        <div>
          <p style={{ margin: 0 }} className="font-dm-medium">Piilotetut sarakkeet</p>

          {hideableCheckboxes.map((item) => (
            <div key={item.hideableId}>
              <Checkbox
                checked={editData?.[item.hideableId]}
                onChange={(e) => {
                  setEditData((prev) => ({
                    ...prev,
                    [item.hideableId]: e.target.checked,
                  }));
                }}
              >
                {item.hover || item.name}
              </Checkbox>
            </div>
          ))}


          <p style={{ margin: 0, marginTop: "1em" }} className="font-dm-medium">Oletustiedot</p>
          <div className="excel-settings-inputs">
            {defaultInputs.map((item) => (
              <DefaultInputItem
                key={item.defaultId}
                label={item.defaultId}
                value={editData[item.defaultId]}
                setValue={(nValue) => {
                  setEditData((prev) => ({
                    ...prev,
                    [item.defaultId]: nValue
                  }));
                }}
                maxLength={item.defaultMaxLength}
              />
            ))}
    
          </div>


          <p style={{ margin: 0, marginTop: "1em" }} className="font-dm-medium">Tarvikkeet - Oletuskaavat</p>

          <div className="excel-settings-inputs">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="materials-list">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {materialsList.map((materialItm, index) => (
                      <Draggable
                        key={materialItm.key}
                        draggableId={materialItm.key}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                            }}
                          >
                            <DefaultInputItem
                              label={materialItm.name}
                              value={materialItm.OletusKaava}
                              setValue={(nValue) =>
                                setMaterialValue(materialItm.key, nValue)
                              }
                              maxLength={99}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>

        </div>
      </Modal>
    </>
  );
};
