import { CloseOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";

import { Input, Modal, Button, Select } from "antd";
import dayjs from "dayjs";
import { useTranslate } from "../../../components/Translate";
import { apiSecure } from "../../../components/api";
import Draggable from 'react-draggable';
import { createRoot } from 'react-dom/client';

export const FormulaDraggableModal = ({ currentValue, onClose, onConfirm, }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef(null);
  const [newValue, setNewValue] = useState(currentValue);

  const inputRef =  useRef(null);


  console.log('currentValue', currentValue)

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 100); // pieni viive varmistaa että modal on täysin renderöity
  }, []);

  return (
    <>
      <Modal
        maskProps={{ id: 'formula-draggable-modal-mask' }}
        styles={
          {
            content: {
              padding: '10px'
            }
          }
        }
        title={
          <div
            style={{
              width: '100%',
              cursor: 'move',
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}
          >
            Muokkaa
          </div>
        }
        open={true}
        /*         onOk={() => onConfirm(newValue)} */
        onCancel={() => onClose(null)}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            nodeRef={draggleRef}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
        footer={[]}
      >
        <Input
          ref={inputRef}
          className="formula-draggable-modal-input"
          value={newValue}
          onChange={(text) => {
            setNewValue(text.target.value)
          }}
          onPressEnter={() => {
            onConfirm(newValue);
          }}
          autoFocus
        />
      </Modal>
    </>
  );
};


// Imperatiivinen avausfunktio
export function openFormulaDraggableModal(currentValue) {
  return new Promise((resolve) => {
    const container = document.createElement('div');
    document.body.appendChild(container);
    const root = createRoot(container);

    const handleClose = (result) => {
      root.unmount();
      container.remove();
      resolve(result);
    };

    root.render(
      <FormulaDraggableModal
        currentValue={currentValue}
        onClose={handleClose}
        onConfirm={(value) => handleClose(value)}
      />
    );
  });
}