import { CloseOutlined, SearchOutlined, UserAddOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";

import { Input, Modal, Button, Select } from "antd";
import dayjs from "dayjs";
import { useTranslate } from "../../components/Translate";
import { apiSecure } from "../../components/api";
import { getEnvSettings } from "../../config/config";
import { LockOutlined, UnlockOutlined } from '@ant-design/icons';


const ModalTextItem = ({ label, value, setValue, disabled = false, canLock = false, locked, setLocked, status = "" }) => {
  const { locale } = useTranslate();

  return (<div>
    <p className="project-modal-label font-dm-medium">{label}</p>
    <Input
      value={value}
      disabled={locked || disabled}
      onChange={(e) => {
        setValue(e.target.value)
        console.log("onChange", e.target.value)
      }}
      status={status}
      addonAfter={canLock ? <>{locked ? <LockOutlined onClick={() => { setLocked(false) }} /> : <UnlockOutlined onClick={() => { setLocked(true) }} />}</> : null}
    />
  </div>
  );
};


export const AddQuotationContactModal = ({ isOpen, closeModal, projectId, refreshData, addedContacts, openAddContact }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { locale } = useTranslate();
  const envConfig = getEnvSettings();
  const [contactsArr, setContactsArr] = useState([]);

  const [selectedContact, setSelectedContact] = useState(false);



  useEffect(() => {

    if (isOpen) {
      getAllContacts()
    } else {
      setSelectedContact(false)
      setContactsArr([])
    }
  }, [isOpen]);

  const saveData = () => {
    setIsLoading(true)

    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}addQuotationContact`,
      data: {
        projectId: projectId,
        contactId: selectedContact,
      },
    };
    console.log("addQuotationContact", req_config);


    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response.data);
        closeModal()
        refreshData()
        setIsLoading(false)
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  }

  const getAllContacts = () => {
    console.log("getAllContacts");
    setIsLoading(true);

    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}getAllContacts`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response);
        console.log('addedContacts', addedContacts)

        // Luodaan `addedContacts` avainarvoista YhteysNRO:n perusteella
        const addedContactsMap = new Set(addedContacts?.map(contact => contact.YhteysNRO));

        // Muokataan contactsArr niin, että lisätään vain puuttuvat kontaktit
        const formattedContacts = response?.data?.data?.yhteystiedot
          .filter(contact => !addedContactsMap.has(contact.YhteysNRO)) // Suodatetaan pois jo lisätyt kontaktit
          .map(contact => ({
            value: contact.YhteysNRO,
            label: contact.Henkilo && contact.Yritys
              ? `${contact.Henkilo} - ${contact.Yritys}`
              : contact.Henkilo || contact.Yritys || "Unknown", // Näytetään molemmat tai toinen, jos vain toinen on olemassa
          }));


        setContactsArr(formattedContacts);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Error:", error);
        //setisLoading(false);
      });
  };

  useEffect(() => {
    getAllContacts()
  }, []);


  return (
    <>
      <Modal
        open={isOpen}
        onCancel={() => closeModal()}
        title={`Lisää kyselijä tarjouspyynnölle`}
        centered={true}
        footer={[
          <Button
            key="addnew"
            variant="solid"
            style={{ backgroundColor: '#14DC40', borderColor: '#18c93f', color: "#FFF" }}
            icon={<UserAddOutlined />}
            iconPosition="end"
            onClick={openAddContact}
            loading={isLoading}
          >
            Luo uusi yhteystieto
          </Button>,
          <Button key="submit" type="primary" color="red" onClick={() => {
            saveData()
          }}
            loading={isLoading}>
            Tallenna
          </Button>,
        ]}
      >
        <div>
          <Select
            showSearch
            placeholder="Valitse yhteystieto"
            optionFilterProp="label"
            value={selectedContact}
            onChange={(value) => {
              setSelectedContact(value)
            }}
            options={contactsArr}
            style={{ width: '100%' }}
          />
          <br/>
        </div>
      </Modal>
    </>
  );
};
