import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { apiSecure } from "../../../components/api";
import { Loading } from "../../../components/Loading/Loading";
import "./ExcelPage.css";

import { MenuFoldOutlined, MenuOutlined, QuestionCircleOutlined, QuestionOutlined, SettingOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslate } from "../../../components/Translate";
import { useDispatch, useSelector } from "react-redux";
import config, { getEnvSettings } from "../../../config/config";
import { NonEditableCell, NumberCell, ReactGrid, TextCell, useReactGridAPI } from "@silevis/reactgrid";
import { Button, Input, notification, Space } from "antd";
import { FormulaCell } from "./FormulaCell";
import { getExcelTypes, getHeaderCells, getInitialElement, getNewElementRow, getRows, getStyledRanges, getSummaryRow, handleResizeColumn, updateRowValues, useDebouncedSave } from "./ExcelFunctions";
import { AddMaterialColumnModal } from "./AddMaterialColumnModal";
import { NonEditCell } from "./NonEditCell";
import { NumberCellOwn } from "./NumberCellOwn";
import { ElementNameCell } from "./ElementNameCell";
import { ExcelSettingsModal } from "./ExcelSettingsModal";
import { ElemHoleCell } from "./ElemHoleCell";
import { ElementHoleModal } from "./ElementHoleModal";
import { BackBtn } from "../../../components/BackBtn/BackBtn";
import { EditText } from "../../../components/EditText/EditText";
import { InfoModal } from "./InfoModal";

const isDebug = false

export const ExcelPage = ({ setToken }) => {
  const [isLoading, setisLoading] = useState(true);
  const gridRef = useRef(null);
  const navigate = useNavigate();

  let { projectId, calcId, groupId } = useParams();
  console.log('ExcelPage', projectId, calcId, groupId)

  const [groupData, setGroupData] = useState({});

  const [elements, setElements] = useState([]);
  const [materialColumns, setMaterialColumns] = useState([]);
  const [defaultFormulas, setDefaultFormulas] = useState({});

  const [isMaterialColumnModalOpen, setIsMaterialColumnModalOpen] = useState(false);

  const [isElementHoleModalOpen, setiIsElementHoleModalOpen] = useState(false);
  const [selectedHoleElement, setSelectedHoleElement] = useState(null);

  const [isSomeLoading, setIsSomeLoading] = useState({
    material: false,
  });
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);


  // Haetaan elementit gridiä varten
  const getElements = () => {
    setisLoading(true);
    console.log("intra_getExcelData");

    let req_config = {
      method: "POST",
      url: `intra_getExcelData`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        groupId: groupId,
      },
    };

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response);
        setGroupData(response?.data?.data?.tiedot);
        setElements(response?.data?.data?.elements);
        setMaterialColumns(response?.data?.data?.materialColumns);
        setDefaultFormulas(response?.data?.data?.defaultFormulas)
        setisLoading(false);
      })
      .catch((error) => {
        console.log("Error:", error);
        setisLoading(false);
      });
  };

  useEffect(() => {
    const isValidProjId = !isNaN(Number(projectId));
    const isValidCalcId = !isNaN(Number(calcId));
    const isValidGroupId = !isNaN(Number(groupId));

    if (!isValidProjId || !isValidCalcId || !isValidGroupId) {
      console.error("Virheellinen ID:", projectId, calcId, groupId);
      navigate("/error");
    } else {
      getElements();
    }
  }, [groupId]);

  // Uuden elementtirivin lisäys
  const addRow = async (newElemText) => {
    console.log("addRow", newElemText);

    let req_config = {
      method: "POST",
      url: `intra_addExcelRow`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        groupId: groupId,
        elemLabel: newElemText
      }
    };

    try {
      const response = await apiSecure(req_config);
      if (!response || !response?.data?.status || !response?.data?.ElementtiId) {
        throw new Error(`Tallennus epäonnistui. Status: ${response?.status}`);
      }
      let elementId = response?.data?.ElementtiId
      if (gridRef.current) {
        gridRef.current.addElementRow(elementId, newElemText);
        notification.open({
          message: "Uusi rivi lisätty",
          placement: 'top',
          type: 'success'
        });
      }

    } catch (error) {
      console.error("Error22:", error.message || error);
      notification.open({
        message: "Rivin lisäämisessä tapahtui virhe! Päivitä sivu ja yritä uudelleen",
        placement: 'top',
        type: 'error'
      });
    }
  };

  // Elementtirivin tallennus
  const saveRow = async (elemData) => {
    console.log("saveRow", elemData);

    let req_config = {
      method: "POST",
      url: `intra_saveExcelData`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        elemData: elemData
      }
    };

    try {
      const response = await apiSecure(req_config);
      if (!response || !response?.data?.status) {
        throw new Error(`Tallennus epäonnistui. Status: ${response?.status}`);
      }
    } catch (error) {
      console.error("Error:", error.message || error);
      throw error; // Heitä virhe eteenpäin, jos haluat käsitellä sen muualla
    }
  };

  const deleteRow = async (elementId) => {
    console.log("deleteRow", elementId);

    let req_config = {
      method: "POST",
      url: `intra_deleteExcelRow`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        elementId: elementId
      }
    };

    try {
      const response = await apiSecure(req_config);
      if (!response || !response?.data?.status) {
        throw new Error(`Rivin poisto epäonnistui. Status: ${response?.status}`);
      }

      notification.open({
        message: "Rivin poistettu",
        placement: 'top',
        type: 'success'
      });

      if (gridRef.current) {
        gridRef.current.deleteElementRow(elementId);
      }

    } catch (error) {
      console.error("Error22:", error.message || error);
      notification.open({
        message: "Rivin poistamisessa tapahtui virhe! Päivitä sivu ja yritä uudelleen",
        placement: 'top',
        type: 'error'
      });
    }
  };

  // Tarvikecolumin lisäys
  const addMaterialColumn = async (materialId) => {
    console.log("addMaterialColumn", materialId);

    setIsSomeLoading((prev) => ({
      ...prev,
      material: true
    }));


    let req_config = {
      method: "POST",
      url: `intra_addExcelMaterialColumn`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        groupId: groupId,
        materialId: materialId
      }
    };

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response);
        getElements()
      })
      .catch((error) => {
        console.log("Error:", error);
      })
      .finally(() => {
        setIsSomeLoading((prev) => ({
          ...prev,
          material: false
        }));
      })
  };


  const deleteMaterialColumn = async (materialId) => {
    console.log("deleteMaterialColumn", materialId);

    let req_config = {
      method: "POST",
      url: `intra_deleteExcelMaterialColumn`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        materialId: materialId
      }
    };

    try {
      const response = await apiSecure(req_config);
      if (!response || !response?.data?.status) {
        throw new Error(`Materiaalin poisto epäonnistui. Status: ${response?.status}`);
      }

      notification.open({
        message: "Materiaali poistettu",
        placement: 'top',
        type: 'success'
      });

      getElements()

      /*       if (gridRef.current) {
              gridRef.current.deleteElementRow(elementId);
            } */

    } catch (error) {
      console.error("Error22:", error.message || error);
      notification.open({
        message: "Materiaalin poistamisessa tapahtui virhe! Päivitä sivu ja yritä uudelleen",
        placement: 'top',
        type: 'error'
      });
    }
  };


  //Tyhjentää tiedot
  const clearExcel = async () => {
    console.log("clearExcel",);
    setisLoading(true);

    let req_config = {
      method: "POST",
      url: `intra_clearExcel`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        groupId: groupId,
      }
    };

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response);
        window.location.reload();

        setisLoading(false);
      })
      .catch((error) => {
        console.log("Error:", error);
        setisLoading(false);
      })
  };

  const updateExcelGroupName = async (nElementtiryhma, nPinnat) => {
    console.log("updateExcelGroupName", nElementtiryhma, nPinnat);

    let req_config = {
      method: "POST",
      url: `intra_updateExcelGroupName`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        groupId: groupId,
        elementGroup: nElementtiryhma,
        surfaces: nPinnat
      }
    };

    apiSecure(req_config)
      .then((response) => {
        if (!response || !response?.data?.status) {
          throw new Error(`Otsikoiden päivityksessä tapahtui virhe!. Status: ${response?.status}`);
        }

        notification.open({
          message: "Otsikko päivitetty",
          placement: 'top',
          type: 'success'
        });
      })
      .catch((error) => {
        console.log("Error:", error);
        notification.open({
          message: "Otsikoiden päivityksessä tapahtui virhe! Päivitä sivu ja yritä uudelleen",
          placement: 'top',
          type: 'error'
        });
      })
  };

  const openElementHoleModal = async (val) => {
    console.log("openElementHoleModal", val);
    setSelectedHoleElement(val)
    setiIsElementHoleModalOpen(true)
  };

  if (isLoading) {
    return <Loading />;
  }

  return (<div>
    <div className="excel-page-main">
      <AddMaterialColumnModal
        isOpen={isMaterialColumnModalOpen}
        closeModal={() => {
          setIsMaterialColumnModalOpen(false);
        }}
        currentMaterials={materialColumns.map(material => material.Tarvike)}
        saveFunc={(materialId) => {
          addMaterialColumn(materialId)
        }}
      />

      <ExcelSettingsModal
        isOpen={isSettingsModalOpen}
        closeModal={() => {
          setIsSettingsModalOpen(false);
        }}
        groupId={groupId}
        groupData={groupData}
        materialColumns={materialColumns}
        refreshData={getElements}
      />

      <InfoModal
        isOpen={infoModalOpen}
        closeModal={() => {
          setInfoModalOpen(false);
        }}
        defaultFormulas={defaultFormulas}
      />

      <ElementHoleModal
        isOpen={isElementHoleModalOpen}
        closeModal={() => {
          setiIsElementHoleModalOpen(false);
          setSelectedHoleElement(null)
        }}
        selectedHoleElement={selectedHoleElement}
        saveFunc={(elementId, holeM2) => {
          console.log('SaveFunc ElementHoleModal', elementId, holeM2)
          if (gridRef.current) {
            gridRef.current.updateElementHole(elementId, holeM2);
          }
        }}
      />

      <div className="excel-page-content">
        <div className={`excel-page-header`}>
          <BackBtn style={{ marginBottom: 5 }} onClick={() => {
            navigate(-1);
          }} />

          <EditText className={"header-text-1 font-itc"} text={groupData.Elementtiryhma} maxLength={99}
            onUpdate={(newText) => {
              console.log('Elementtiryhma newText', newText)
              setGroupData((prev) => ({
                ...prev,
                Elementtiryhma: newText
              }));
              updateExcelGroupName(newText, groupData.Pinnat)
            }}
          />
          <EditText className={"header-text-2 font-dm-reular"} text={groupData.Pinnat} maxLength={99}
            onUpdate={(newText) => {
              console.log('Pinnat newText', newText)
              setGroupData((prev) => ({
                ...prev,
                Pinnat: newText
              }));
              updateExcelGroupName(groupData.Elementtiryhma, newText)
            }}
          />
        </div>


        <div className="grid-buttons">
          {/*           <Button
            type="primary"
            onClick={() => {
              clearExcel()
            }}
            style={{ marginLeft: '1em', backgroundColor: '#000', borderColor: '#000', color: '#FFF' }}
          >
            Tyhjennä taulu
          </Button>
 */}

<Button
            type="primary"
            onClick={() => {
              navigate(`/quotation/${projectId}/calculation/${calcId}/group/${groupId}/calculation`)
            }}
            style={{ marginLeft: '1em' }}
            loading={isSomeLoading["material"]}
          >
            Avaa laskenta
          </Button>

          <Button
            type="primary"
            onClick={() => {
              setIsMaterialColumnModalOpen(true);
            }}
            style={{ marginLeft: '1em' }}
            loading={isSomeLoading["material"]}
          >
            Lisää tarvike
          </Button>

          <SettingOutlined
            style={{ marginLeft: '1em', color: '#BDBDBD' }}
            onClick={() => {
              setIsSettingsModalOpen(true);
            }} />

          <QuestionCircleOutlined
            style={{ marginLeft: '1em', color: '#BDBDBD' }}
            onClick={() => {
              setInfoModalOpen(true);
            }} />
        </div>

        <div className="react-grid-container">
          <ReactGridComponent ref={gridRef} typeId={groupData?.LaskentaTaulukko} groupData={groupData} elements={elements} materialColumns={materialColumns} getElements={getElements} addRow={addRow} deleteRow={deleteRow} saveRow={saveRow} deleteMaterialColumn={deleteMaterialColumn} setIsMaterialColumnModalOpen={setIsMaterialColumnModalOpen} openElementHoleModal={openElementHoleModal} defaultFormulas={defaultFormulas} />
        </div>
      </div>
    </div>
  </div>

  );
};


// ReactGridComponent-komponentti
const ReactGridComponent = forwardRef(({ typeId, groupData, elements, materialColumns,  addRow, deleteRow, saveRow, deleteMaterialColumn, openElementHoleModal, defaultFormulas }, ref) => {
  const [excelTypes, setExcelTypes] = useState(getExcelTypes(typeId, materialColumns));
  const [columns, setColumns] = useState([]);
  const [elementsData, setElementsData] = useState([]);
  const [lastCellFocused, setLastCellFocused] = useState(null);

  const gridApi = useReactGridAPI("exceltest")
  const [hidedColumns, setHidedColumns] = useState([]);

  // Määrittele funktiot, jotka haluat refin kautta kutsuttaviksi
  useImperativeHandle(ref, () => ({
    // elementtiRivin lisäys
    addElementRow: (elementId, elementLabel) => {
      console.log("addElementRow:", elementId, elementLabel);
      // Päivitä data tässä tarvittaessa
      let nElementData = getInitialElement(excelTypes,
        {
          ElementtiId: elementId,
          Tunnus: elementLabel,
/*           Korkeus: groupData?.OletusKorkeusKaava,
          PaksuusSisa: groupData?.OletusPaksuusSisaKaava,
          PaksuusEriste: groupData?.OletusPaksuusEristeKaava,
          PaksuusUlko: groupData?.OletusPaksuusUlkoKaava,
          PainoTerasKaava: groupData?.OletusTerasKaava,
          PainoTerasRstKaava: groupData?.OletusTerasRstKaava, */
/*           Korkeus: groupData?.OletusJalkaSyvyysKaava,
          Korkeus: groupData?.OletusJalkaPaksuusKaava, */
 
        },
        "unsaved"
      );

      setElementsData((prev) => [...prev, nElementData]);
      console.log('elementsData222', elementsData)

      console.log('getFocusedCell', gridApi.getFocusedCell())

      const currFocusedCell = gridApi.getFocusedCell()
      gridApi.setFocusedCell({
        rowIndex: currFocusedCell.rowIndex,
        colIndex: currFocusedCell.colIndex,
      })
      setTimeout(function () {
        gridApi.setFocusedCell({
          rowIndex: currFocusedCell.rowIndex,
          colIndex: currFocusedCell.colIndex,
        })
      }, 50);
      /*       if (elementsData.length >= 1) {
              gridApi.setFocusedCell({
                rowIndex: elementsData.length + 1,
                colIndex: 1
              })
              setTimeout(function () {
                gridApi.setFocusedCell({
                  rowIndex: elementsData.length + 1,
                  colIndex: 1
                })2
              }, 50);
            } */
    },

    // elementtiRivin poisto
    deleteElementRow: (elementId) => {
      console.log("deleteElementRow:", elementId);
      setElementsData((prev) => prev.filter(element => element.id !== elementId));
    },

    // elementin aukko päivitys
    updateElementHole: (elementId, holeM2) => {
      console.log("updateElementHole:", elementId, holeM2);

      const parsedValue = parseFloat(holeM2);
      if (isNaN(parsedValue)) {
        console.warn("Invalid holeM2 value:", holeM2);
        return;
      }

      setElementsData((prev) =>
        prev.map(row => {
          if (row.id !== elementId) return row;

          const currentValue = row.values?.am2?.value;
          if (currentValue === parsedValue) return row; // Ei muutosta, ei päivitetä

          return {
            ...row,
            values: {
              ...row.values,
              am2: {
                ...row.values?.am2,
                value: parsedValue,
                formula: holeM2
              }
            },
            status: "unsaved"
          };
        })
      );
    },
  }));



  // Elements Initial
  useEffect(() => {
    if (elements.length <= 0) return;
    const formattedElements = elements.map((elemData) => (getInitialElement(excelTypes, elemData)));
    setElementsData(formattedElements);
  }, [excelTypes]);

  // Columns Initial
  useEffect(() => {
    setColumns(Object.values(excelTypes).map((row, colIndex) => ({
      colIndex: colIndex,
      minWidth: "30px",
      width: row?.width ? row?.width : "50px"
    })))
  }, [excelTypes]);

  //Jos lisätään materiaalicolumn, päivitetään excelTypes- state
  useEffect(() => {
    setExcelTypes(getExcelTypes(typeId, materialColumns));
  }, [materialColumns]);


  // Piilotettavien sarakkeiden määritys
  useEffect(() => {
    if (groupData) {
      const columnsToCheck = [
        { key: "PiilotettuPituusKaantyvatPaat", columnId: "Lp" },
        { key: "PiilotettuKonsoliM3", columnId: "km3" },
        { key: "PiilotettuPainoTerasRst", columnId: "rstkg" },
        // Lisää tänne helposti lisää kun tarvitsee
      ];

      const nHidedCols = columnsToCheck
        .filter(col => groupData[col.key])
        .map(col => col.columnId);

      setHidedColumns(nHidedCols);
    }
  }, [groupData]);

  // Viimeisen kohdalla Tab hyppää seuraavaan
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Tab") {
        event.preventDefault(); // Estetään oletustoiminto
        console.log('Pressed Tab', lastCellFocused)

        gridApi.setFocusedCell({
          rowIndex: lastCellFocused?.rowIndex + 1,
          colIndex: 0
        })
        setLastCellFocused(null)
      };
    }

    if (lastCellFocused === null) {
      return
    }

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [lastCellFocused]);


  // Käytetään debounce-tallennusta
  useDebouncedSave(elementsData, setElementsData, saveRow);

  // Muodostetaan kaikki cells
  const cells = [
    // Header row
    ...getHeaderCells(excelTypes, hidedColumns, deleteMaterialColumn),

    // Element rows
    ...elementsData
      .map((elemData, rowIndex) => {
        return [
          ...Object.keys(elemData.values)
            .filter((valueType) => !hidedColumns.includes(valueType))
            .map((valueType, colIndex) => {
              const valueRow = elemData.values[valueType]
              let typeRow = excelTypes[valueType]


              //  "element-name"
              if (typeRow.type === "element-name") {
                return {
                  rowIndex: rowIndex + 1,
                  colIndex: colIndex,
                  Template: ElementNameCell,
                  props: {
                    text: valueRow.value,
                    onTextChanged: (newValue) => {

                      // Ei päivitetä jos sama arvo
                      if (valueRow.value === newValue) return

                      setElementsData((prev) =>
                        updateRowValues(prev, rowIndex, valueType, newValue, false, defaultFormulas)
                      )
                    },
                    style: { backgroundColor: isDebug ? 'DFFF00' : '' },
                    status: elemData.status,
                    menuOnClick: ({ key }) => {
                      console.log('menuOnClick', key)
                      if (key === "delete") {
                        deleteRow(elemData.id)
                      }
                    },
                  }
                }
              }

              //  "static"
              else if (typeRow.type === "static") {
                return {
                  rowIndex: rowIndex + 1,
                  colIndex: colIndex,
                  Template: NonEditCell,
                  props: {
                    value: valueRow.value,
                    round: 1,
                    style: cellStyles.disableEdit
                  },

                };
              }


              //  "element-hole"
              else if (typeRow.type === "element-hole") {
                return {
                  rowIndex: rowIndex + 1,
                  colIndex: colIndex,
                  Template: ElemHoleCell,
                  props: {
                    value: valueRow.value,
                    formula: valueRow.formula,
                    round: 1,
                    onOpen: () => {
                      openElementHoleModal(elemData)
                    },
                    onFormulaChanged: (newValue) => {
                      console.log('newFormulaValue', valueRow.formula, newValue)

                      // Ei päivitetä jos sama arvo
                      if (valueRow.formula === newValue) return

                      setElementsData((prev) =>
                        updateRowValues(prev, rowIndex, valueType, newValue, true, defaultFormulas)
                      );
                    },
                    style: { backgroundColor: isDebug ? 'green' : '' }
                  }
                }
              }

              // formula
              else if (typeRow.type === "formula") {
                return {
                  rowIndex: rowIndex + 1,
                  colIndex: colIndex,
                  Template: FormulaCell,
                  props: {
                    value: valueRow.value,
                    formula: valueRow.formula,
                    round: 1,
                    id: valueRow.id,
                    onFormulaChanged: (newValue) => {
                      console.log('newFormulaValue', valueRow.formula, newValue)

                      // Ei päivitetä jos sama arvo
                      if (valueRow.formula === newValue) return

                      setElementsData((prev) =>
                        updateRowValues(prev, rowIndex, valueType, newValue, true, defaultFormulas)
                      );
                    },
                    style: { backgroundColor: isDebug ? 'green' : '' }
                  }
                }
              }

              // numeric
              else if (typeRow.type === "numeric") {
                return {
                  rowIndex: rowIndex + 1,
                  colIndex: colIndex,
                  Template: NumberCellOwn,
                  props: {
                    allowSeparators: false,
                    value: valueRow.value,
                    onValueChanged: (newValue) => {
                      console.log('onValueChanged', valueRow.value, newValue)

                      // Ei pävitetä jos arco ei ole numeraalinen
                      if (newValue && isNaN(newValue)) return

                      // Ei päivitetä jos sama arvo
                      if (valueRow.value === newValue) return

                      setElementsData((prev) =>
                        updateRowValues(prev, rowIndex, valueType, newValue, false, defaultFormulas)
                      );
                    },
                    style: { backgroundColor: isDebug ? 'yellow' : '' }
                  }
                }
              }


              // Ei määriteltyä
              else {
                return {
                  rowIndex: rowIndex + 1,
                  colIndex: colIndex,
                  Template: NonEditableCell,
                  props: {
                    value: valueRow.value,
                    style: { backgroundColor: '#f1c27d' }
                  },
                };
              }
            }),
        ]
      })
      .flat(),


    //...getHeaderCells(excelTypes, hidedColumns),
    ...getNewElementRow(addRow, excelTypes, hidedColumns, elementsData, groupData?.OletusTunnus),

    // Summary row
    ...getSummaryRow(excelTypes, elementsData, hidedColumns),
  ];

  // Modostetaan rivien korkeus
  const rows = getRows(elementsData);

  return <ReactGrid
    id="exceltest"
    rows={rows}
    cells={cells}
    columns={columns}
    styles={rgStyles}
    stickyLeftColumns={1}
    stickyTopRows={1}
    stickyBottomRows={1}
    onResizeColumn={(width, columnIdx) => handleResizeColumn(width, columnIdx, setColumns)}
    onCellFocused={(cellLocation) => {
      //Rivin Viimeisen cellin kohdalla kerrotaan statelle että on viimeinen, Tämä koska halutaan hypätä seuraavalle riville
      console.log('onCellFocused', cellLocation, columns.length)
      if (cellLocation?.colIndex === columns.length - 1) {
        //console.log('Viimeinen Column')
        setLastCellFocused(cellLocation)
      }
    }}

  />
});


const cellStyles = {
  disableEdit: {
    backgroundColor: "#F9F9F9",
  },
  footer: {
    backgroundColor: "#F9F9F9",
    display: "flex",
    alignItems: "center",
    fontFamily: "DM Sans Bold",
  }
};

const rgStyles = {
  focusIndicator: {
    border: {
      color: "#0C2FFA",
      width: "2px",
      style: "solid",
    },
  },
  selectionIndicator: {
    background: "rgba(12,47,250,0.1)",
    border: {
      color: "#0C2FFA",
      style: "solid",
    },
  },
  fillHandle: {
    background: "transparent",
    border: {
      color: "#0C2FFA",
      style: "dashed",
    },
  },
  gridWrapper: {
    fontSize: 16,
    fontFamily: "DM Sans Regular",
    borderRadius: 20
  },
};









