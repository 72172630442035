import { useCallback, useEffect, useState } from "react";
import { apiSecure } from "../../components/api";
import { Loading, LoadingItem } from "../../components/Loading/Loading";

import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslate } from "../../components/Translate";
import { useDispatch, useSelector } from "react-redux";
import config, { getEnvSettings } from "../../config/config";
import { BackBtn } from "../../components/BackBtn/BackBtn";

export const QuotationGroupCalculation = ({ setToken }) => {
  const [isLoading, setisLoading] = useState(true);
  const dispatch = useDispatch();
  const { locale } = useTranslate();
  const navigate = useNavigate();
  const envConfig = getEnvSettings();

  let { projectId, calcId, groupId } = useParams();
  console.log('QuotationGroupCalculation', projectId, calcId, groupId)
  const [calculationData, setCalculationData] = useState({});

  // Tietojen haku
  const getQuotationGroupCalc = () => {
    setisLoading(true);
    console.log("getQuotationGroupCalc");

    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}getQuotationGroupCalc`,
      data: {
        projectId: projectId,
        calcId: calcId,
        groupId: groupId
      },
    };
    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response);
        setCalculationData(response?.data?.data)
        setisLoading(false);
      })
      .catch((error) => {
        console.log("Error:", error);
        setisLoading(false);
      });
  };

  useEffect(() => {
    const isValidProjId = !isNaN(Number(projectId));
    const isValidCalcId = !isNaN(Number(calcId));
    const isValidGroupId = !isNaN(Number(groupId));

    if (!isValidProjId || !isValidCalcId || !isValidGroupId) {
      console.error("Virheellinen ID:", projectId, calcId, groupId);
      navigate("/error");
    } else {
      getQuotationGroupCalc();
    }
  }, [groupId]);

  if (isLoading) {
    return <Loading />;
  }

  if (!calculationData.tiedot) {
    return (
      <div className="projects-nodata">
        <p className="projects-nodata-text font-dm-bold">Elementtiryhmän laskentatietoja ei löydy tai haussa ilmeni ongelmia!</p>
      </div>
    );
  }

  return (
    <div className="projectmain">
      <div className={`projectpage-content`}>
        <div className={`excel-page-header`}>
          <BackBtn style={{ marginBottom: 5 }} onClick={() => {
            navigate(-1);
          }} />

          <p className={"header-text-1 font-itc"}>
            {calculationData.tiedot.Elementtiryhma}
          </p>

          <p className={"header-text-2 font-dm-reular"}>
            {calculationData.tiedot.Pinnat}
          </p>
        </div>



        <div className="quotation-group-calculation">

          <div className="title-row">
            <div>
              <p className="title font-dm-bold">Tuotekohtainen kustannuslaskenta</p>
              <p className="text2 font-dm-regular">Tuotekohtaiset kiinteät kustannukset 18 %</p>
            </div>

            <div className="totals">
              <p><span className="font-dm-bold">1322,42</span> €/kpl</p>
              <p><span className="font-dm-bold">779,52</span> €/m3</p>
              <p><span className="font-dm-bold">132,42</span> €/m2</p>
              <p><span className="font-dm-bold">35 395</span> €</p>
            </div>
          </div>




        </div>

      </div>
    </div>
  );
};
