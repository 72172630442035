import { useCallback, useEffect, useState } from "react";
import { apiSecure } from "../../components/api";
import { Loading, LoadingItem } from "../../components/Loading/Loading";

import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslate } from "../../components/Translate";
import { useDispatch, useSelector } from "react-redux";
import config, { getEnvSettings } from "../../config/config";
import { EditContactModal } from "../Project/ProjectInfo/EditContactModal";
import { BackBtn } from "../../components/BackBtn/BackBtn";
import { useMediaQuery } from "react-responsive";
import { getParsedDate, openMaps } from "../../components/globalFunctions";
import { Divider, Modal } from "antd";
import { RightOutlined, MenuOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { AddQuotationCalcElementgroupModal } from "./AddQuotationCalcElementgroupModal";



export const QuotationCalculation = ({ setToken }) => {
  const [isLoading, setisLoading] = useState(true);
  const dispatch = useDispatch();
  const { locale } = useTranslate();
  const navigate = useNavigate();
  const envConfig = getEnvSettings();

  let { projectId, calcId } = useParams();
  const [quotationData, setQuotationData] = useState({});

  const isMobile = useMediaQuery({ maxWidth: 500 });
  const [isAddElementgroupModalOpen, setIsAddElementgroupModalOpen] = useState(false);

  // Tietojen haku
  const getQuotationCalc = () => {
    setisLoading(true);
    console.log("getQuotationCalc");

    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}getQuotationCalc`,
      data: {
        projectId: projectId,
        calcId: calcId,
      },
    };

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response);
        setQuotationData(response?.data?.data)
        setisLoading(false);
      })
      .catch((error) => {
        console.log("Error:", error);
        setisLoading(false);
      });
  };

  useEffect(() => {
    const isValidProjId = !isNaN(Number(projectId));
    const isValidCalcId = !isNaN(Number(calcId));
    if (!isValidProjId || !isValidCalcId) {
      console.error("Virheellinen ID:", isValidProjId, isValidCalcId);
      navigate("/error");
    } else {
      getQuotationCalc()
    }
  }, [projectId]);

  if (isLoading) {
    return <Loading />;
  }

  if (!quotationData.tiedot) {
    return (
      <div className="projects-nodata">
        <p className="projects-nodata-text font-dm-bold">Tarjousta ei löydy tai haussa ilmeni ongelmia!</p>
      </div>
    );
  }

  return (
    <div className="projectmain">
      <div className={`projectpage-content`}>
        <div className={`project-header-12 `}>
          <BackBtn style={{ marginBottom: 5 }} onClick={() => {
            navigate(-1);
          }} />

          {!isMobile ?
            <div className="project-header-smallrow">
              <p className="project-header-id font-dm-bold">
                {quotationData.tiedot.Tyokohdenro}
              </p>
            </div>
            : <></>}

          <h1 className="project-header font-itc">
            {quotationData.tiedot.Nimi}
          </h1>
        </div>

        <AddQuotationCalcElementgroupModal
          isOpen={isAddElementgroupModalOpen}
          closeModal={() => {
            setIsAddElementgroupModalOpen(false);
          }}
          calcId={calcId}
          refreshData={(newGroupId)=>{
            getQuotationCalc()
            setIsAddElementgroupModalOpen(false);
            navigate(`/quotation/${projectId}/calculation/${calcId}/group/${newGroupId}`)
          }}
        />

        <div className="quotation-info">
          <p className="quotation-info-title font-dm-bold">Tarjouslaskenta</p>

          <div className="quotation-info-box">
            <p className="quotation-info-box-calc-header font-dm-bold">{quotationData?.calculation?.Kuvaus}</p>
            <Divider style={{ marginTop: "5px" }} />

            {quotationData?.elementGroups?.length >= 1 && quotationData?.elementGroups?.map((elementGroup) => {
              console.log('elementGroup', elementGroup)
              let groupId = elementGroup?.RyhmaId
              return (
                <Link to={`/quotation/${projectId}/calculation/${calcId}/group/${groupId}`} key={groupId} style={{ textDecoration: 'none' }} >
                  <div className="quotation-calculation-elementgroup font-dm-bold">
                    <p className="label font-dm-bold">{elementGroup.Elementtiryhma}</p>
                  </div>
                </Link>
              )
            })}
          </div>

          <div className="quotation-info-box-add" style={{ marginTop: 10 }} onClick={() => {
            setIsAddElementgroupModalOpen(true)
          }}>
            <PlusOutlined className="quotation-info-box-addIcon" />
          </div>
        </div>
      </div>
    </div>
  );
};
