import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./ExcelPage.css";

import { TextCell } from "@silevis/reactgrid";
import { Button, notification } from "antd";

import excelTypesArr from './ExcelTypes.json';
import { HeaderCell } from "./HeaderCell";
import { NonEditCell } from "./NonEditCell";
import { hover } from "@testing-library/user-event/dist/hover";
import { FooterCell } from "./FooterCell";
import { ElementNameCell } from "./ElementNameCell";


/* Tallentaa rivit kun muutoksia ei ole tehty x sekunttiin */
export const useDebouncedSave = (elementsData, setElementsData, saveRow, delay = 3000) => {
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (!elementsData || elementsData.length === 0) return;

    // Kerätään kaikki muuttuneet ja tallennettavat rivit
    const rowsToSave = elementsData.filter(row => row.status !== "saved" && row.status !== "saving" && row.status !== "error");
    if (rowsToSave.length === 0) return; // Ei tallennettavia rivejä

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(async () => {
      for (let row of rowsToSave) {
        try {
          await saveRow(row);

          //Pävitetää status sekä muutetaan originalValue -> valueksi
          setElementsData(prev =>
            prev.map(r =>
              r.id === row.id
                ? {
                  ...r,
                  status: "saved",
                  isUpdated: false,
                  values: Object.fromEntries(
                    Object.entries(r.values).map(([key, itm]) => [
                      key,
                      {
                        ...itm,
                        originalValue: itm.originalValue !== itm.value ? itm.value : itm.originalValue
                      }
                    ])
                  )
                }
                : r
            )
          );

        } catch (error) {
          setElementsData(prev =>
            prev.map(r =>
              r.id === row.id
                ? { ...r, status: "error", errorMessage: "Tallennus epäonnistui, yritä uudelleen." }
                : r
            )
          );

          notification.open({
            message: "Tallennuksessa tapahtui virhe! Päivitä sivu ja yritä uudelleen",
            placement: 'top',
            type: 'error'
          });
        }
      }
    }, delay);

    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [elementsData, saveRow, delay, setElementsData]);
};





// Tehdään jokaiselle columnille data/tyyppi
export const getExcelTypes = (tableId, materialColumns) => {
  console.log('[getExcelTypes]', tableId)
  
  // Käytetään reduce() luomaan id-pohjainen objekti
  let dataById = excelTypesArr.reduce((acc, item) => {
    if (item.taulut?.length && !item.taulut.includes(tableId)) {
      return acc; // Ohitetaan, jos taulukko ei kuulu tähän tableId:hen
    }

    // Käytetään customName:a jos löytyy tälle tableId:lle
    const customName = item.customNames?.[tableId.toString()] || item.name;

    acc[item.id] = {
      ...item,
      name: customName
    };
    return acc;
  }, {});

  // Lisätään materiaalikolumnit
  materialColumns.forEach(itm => {
    //console.log('itmitm',itm)

    let materialColumnId = `tarvike_${itm.TarvikkeetId}`;
    dataById[materialColumnId] = {
      id: materialColumnId,
      name: itm.Koodi,
      type: "formula",
      hover: `${itm.Nimi} (${materialColumnId})`,
      calculateSumKpl: true,
      calculateSuffix: itm.Yksikko,
      calculateCondRound: 1,
      material: true,
      materialId: itm.TarvikkeetId,
      width: "120px"
    };
  });

  return dataById;
};


function isObject(value) {
  return value && typeof value === 'object' && value.constructor === Object;
}

// Luo exceltypes perusteella elementtiriville arvot
export const createValuesArr = (excelTypes, elemValues, materialValues) => {
  let nArr = {};

  Object.values(excelTypes).forEach((type) => {
    if (type.id === "status") return; // Ohitetaan status-rivi

    let sourceValues = type.material ? materialValues : elemValues; // Valitaan oikea lähde
    let valueObj = sourceValues[type.id];

    // Jos arvo on jo objektina, käytetään sitä
    if (isObject(valueObj)) {
      let nValue = valueObj.value ?? null;
      let nFormula = valueObj.formula ?? null;

      // Jos on kaava, mutta arvo puuttuu, asetetaan "###"
      if (nFormula && nValue === null) {
        nValue = "###";
      }

      nArr[type.id] = {
        id: type.id,
        value: nValue,
        formula: nFormula,
        originalValue: type.material ? nValue : undefined, // originalValue vain materiaaleille
      };
    } else {

      // Jos kyseessä on pelkkä arvo (ei objekti), tallennetaan se suoraan
      nArr[type.id] = {
        id: type.id,
        value: valueObj ?? null, // Suora arvo tai null
        formula: type.formula ?? null, //Esiasetettu formula 
        originalValue: type.material ? valueObj ?? null : undefined, // originalValue vain materiaaleille
      };
    }
  });

  return nArr;
};



// Luodaan elementeistä tietomalli jota gridi käyttää
export const getInitialElement = (excelTypes, elemData, initialStatus="saved") => {
  //console.log('formattedElements getInitialElement', elemData)

  return {
    id: elemData.ElementtiId,
    status: initialStatus,
    values: createValuesArr(
      excelTypes,
      {
        Tunnus: elemData.Tunnus,
        kpl: elemData.Kpl,
        L: elemData.Pituus,
        Lp: elemData.PituusKaantyvatPaat,
        h: elemData.Korkeus,
        ds: elemData.PaksuusSisa,
        de: elemData.PaksuusEriste,
        du: elemData.PaksuusUlko,
        am2: { value: elemData.AukkoM2, formula: elemData.AukkoM2Kaava },
        KonsoliKpl: elemData.KonsoliKpl,
        km3: { value: elemData.KonsoliM3, formula: elemData.KonsoliM3Kaava },
        Jm: elemData.Jm,
        m2: elemData.M2,
        bm2: elemData.BrtM2,
        m3: elemData.M3,
        t: elemData.Paino,
        tkg: { value: elemData.PainoTeras, formula: elemData.PainoTerasKaava },
        rstkg: { value: elemData.PainoTerasRst, formula: elemData.PainoTerasRstKaava },
        Sahaus: elemData.Sahaus
      },
      elemData.materialValues || {}
    ),
  }
};



// Lasketaan formulat
export const calculateFormulas = (row, defaultFormulas = []) => {

  // Muunnetaan defaultFormulas koodiksi ja esikompiloiduiksi regexeiksi
  const formulaMap = defaultFormulas.map((dFormula) => {
    const key = dFormula.Koodi;
    const value = dFormula.Kaava.replace(",", "."); // Tee tämä jo tässä
    const escapedKey = key.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = /^[a-zA-Z0-9_]+$/.test(key)
      ? new RegExp(`\\b${escapedKey}\\b`, 'g')
      : new RegExp(`${escapedKey}`, 'g');
    return { key, value, regex };
  });

  console.log('formulaMap',formulaMap)

  Object.values(row.values).forEach((item) => {
    if (!item.formula) return;
    console.log(`calculateFormulas [${item.id}]`, item.formula);

    if (!item.formula.startsWith("=")) {
      const numValue = Number(item.formula);
      row.values[item.id].value = isNaN(numValue) ? "###" : numValue;
      return;
    }

    try {
      let formulaExpression = item.formula.trim().replace(/^=/, "");

      // Korvataan vakioformulat regexeillä
      formulaMap.forEach(({ regex, value }) => {
        formulaExpression = formulaExpression.replace(regex, `(${value})`);
      });

      // Korvataan muuttujat arvoilla
      excelTypesArr.forEach(({ id }) => {
        const value = row.values[id]?.value ?? 0;
        const varRegex = new RegExp(`\\b${id}\\b`, 'g');
        formulaExpression = formulaExpression.replace(varRegex, value);
      });


      console.log(`Lasketaan kaavaa [${item.id}]`, { raw: item.formula, parsed: formulaExpression });

      if (formulaExpression.includes("###")) {
        row.values[item.id].value = "###";
        return;
      }

      const result = new Function(`return ${formulaExpression}`)();
      row.values[item.id].value = result ?? "###";
    } catch (e) {
      console.error(`Virhe laskettaessa kaavaa: ${item.formula}`, e);
      row.values[item.id].value = "###";
    }
  });

  return row.values;
};
// Tämä triggeröituu kun jonkin rivin kenttään muuttuu arvo.
export const updateRowValues = (rows, rowIndex, valueType, newValue, isFormula = false, defaultFormulas) => {
  console.log('updateRowValues', valueType, newValue, isFormula)

  // Määritetään rivi jota muokataan
  const rowToUpdate = rows[rowIndex];
  if (!rowToUpdate) return rows;


  const updatedRow = { ...rowToUpdate };

  // Jos on kaava, muokataan kaavaa.
  if (isFormula) {
    updatedRow.values[valueType].formula = newValue;
    if (!newValue) {
      updatedRow.values[valueType].value = 0;
    }
  } else {
    updatedRow.values[valueType].value = newValue;
  }

  console.log('updatedRow', updatedRow)

  // Lasketaan rivin formula arvot
  updatedRow.values = calculateFormulas(updatedRow, defaultFormulas);
  updatedRow.status = "unsaved";

  // Päivitetään vain muuttunut rivi, ei koko arrayta
  const updatedRows = rows.map((row, index) => (index === rowIndex ? updatedRow : row));

  return updatedRows;
};





// Lasketaan summat SummaryRowia varten
// Lasketaan summat SummaryRowia varten
function calculateSums(elementsData, excelTypes) {
  let sums = {};

  Object.keys(excelTypes).forEach(key => {
    // Lasketaan rivien arvot yhteen
    if (excelTypes[key].calculateSum) {
      let sum = elementsData.reduce((sum, row) => {
        const value = row.values[key]?.value;
        return value !== "###" ? sum + (value ? value : 0) : sum;
      }, 0);

      // Jaetaan summalla, jos calculateDivide on määritelty
      if (excelTypes[key].calculateDivide) {
        sum /= excelTypes[key].calculateDivide;
      }

      sums[key] = sum;
    }

    // Lasketaan rivien arvot*kpl yhteen
    if (excelTypes[key].calculateSumKpl) {
      let sum = elementsData.reduce((sum, row) => {
        const kpl = row.values["kpl"]?.value ? row.values["kpl"]?.value : 0;  // Oletetaan 1 jos Kpl ei ole määritelty
        const value = row.values[key]?.value;
        return value !== "###" ? sum + ((value ?? 0) * kpl) : sum;
      }, 0);

      // Jaetaan summalla, jos calculateDivide on määritelty
      if (excelTypes[key].calculateDivide) {
        sum /= excelTypes[key].calculateDivide;
      }

      sums[key] = sum;
    }


    //Juoksumetrien laskenta
    if (key === "L") {
      // Lasketaan rivien arvot*kpl yhteen
      let sum = elementsData.reduce((sum, row) => {
        const kpl = row.values["kpl"]?.value ? row.values["kpl"]?.value : 0;  // Oletetaan 1 jos Kpl ei ole määritelty
        const value = row.values["L"]?.value + row.values["Lp"]?.value;
        return value !== "###" ? sum + ((value ?? 0) * kpl) : sum;
      }, 0);

      // Jaetaan 1000
      sum /= 1000;

      sums["L"] = sum;
    }


  });

  return sums;
}

export const getSummaryRow = (excelTypes, elementsData, hidedColumns) => {
  //SummaryRow
  const sumsData = calculateSums(elementsData, excelTypes);

  let colCount = Object.keys(excelTypes)?.length


  let showableColumns = Object.values(excelTypes).filter((itm) => !hidedColumns.includes(itm.id))
  return showableColumns.map((type, colIndex) => ({
    rowIndex: elementsData.length + 2,
    colIndex: colIndex,
    Template: FooterCell,
    props: {
      value: type.calculateText ? type.calculateText : sumsData[type.id],
      suffix: type.calculateSuffix,
      round: type.calculateRound,
      conditionalRound: type.calculateCondRound,
      style: cellStyles.footer,
      bottomLeft: colIndex === 0,
      bottomRight: colIndex === colCount - 1

    },
    isFocusable: true,
    isSelectable: false
  }));
};
























// GRIDIN APUFUNKTIOIA
// Muodostetaan rivien korkeudet
export const getHeaderCells = (headerCols, hidedColumns, deleteMaterialColumn) => {
  let colCount = Object.keys(headerCols)?.length

  let showableColumns = Object.values(headerCols).filter((itm) => !hidedColumns.includes(itm.id))
  console.log('showableColumns', showableColumns)

  return showableColumns.map((row, colIndex) => ({
    rowIndex: 0,
    colIndex,
    Template: HeaderCell,
    props: {
      value: row.name,
      id: row.id,
      hover: row.hover,
      style: cellStyles.header,
      topLeft: colIndex === 0,
      topRight: colIndex === colCount - 1,
      clickDeleteMaterial: () => { deleteMaterialColumn(row?.materialId) }
    },
    isFocusable: false,
    isSelectable: false
  }));
};

export const getNewElementRow = (addRow, excelTypes, hidedColumns, elementsData, defaultIdPrefix) => {
  let showableColumns = Object.values(excelTypes).filter((itm) => !hidedColumns.includes(itm.id))
  return showableColumns.map((typeRow, colIndex) => ({
    rowIndex: elementsData.length + 1,
    colIndex: colIndex,
    Template: colIndex === 0 ? ElementNameCell : NonEditCell,
    props: {
      text: '',
      onTextChanged: async (elementLabel) => {
        console.log('New element label got', elementLabel)
        if (!elementLabel || elementLabel.length <= 0) return;
        await addRow(elementLabel);
      },
      status: "saved",
      isNew: true,
      defaultIdPrefix: defaultIdPrefix,
      style: typeRow.type === "static" ? cellStyles.disableEdit : {}
    },
  }));
};





export const getRows = (nRows) => [
  // header row
  {
    rowIndex: 0,
    height: 45,
  },
  // data rows
  ...nRows.map((_, i) => ({
    rowIndex: i + 1,
    height: 35,
  })),

  //New row
  {
    rowIndex: nRows.length + 1,
    height: 35,
  },

  //New row
  {
    rowIndex: nRows.length + 2,
    height: 45,
  },
];

export const handleResizeColumn = (
  newWidth,
  columnIndexes,
  setColumns
) => {
  setColumns((prevColumns) => {
    // If resizing spans multiple columns (e.g., header cells with colSpan > 1), divide the new width by the number of columns
    const newWidthPerColumn = columnIndexes.length > 1 ? newWidth / columnIndexes.length : newWidth;

    return prevColumns.map((column, idx) => {
      if (columnIndexes.includes(idx)) {
        return { ...column, width: newWidthPerColumn };
      }

      return column;
    });
  });
};












const cellStyles = {
  header: {
    backgroundColor: "#F9F9F9",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "DM Sans Bold",
    overflow: 'hidden'
  },
  disableEdit: {
    backgroundColor: "#F9F9F9",
  },
  footer: {
    backgroundColor: "#F9F9F9",
    display: "flex",
    alignItems: "center",
    fontFamily: "DM Sans Bold",
  }
};
