import { CloseOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";


import { Button, DatePicker, Input, InputNumber, Modal, Select, TimePicker } from "antd";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';

import { useTranslate } from "../../../components/Translate";
import { apiSecure } from "../../../components/api";
import { getEnvSettings } from "../../../config/config";

dayjs.extend(utc);

const { TextArea } = Input;


export const EditDeliveryData = ({ isOpen, closeModal, projectId, deliveryData, getProject }) => {
  console.log("EditDeliveryData data", deliveryData);
  const envConfig = getEnvSettings();


  const [loading, setLoading] = useState(true);

  const [vehicles, setVehicles] = useState([]);

  const { locale } = useTranslate();

  const [currDate, setCurrDate] = useState(dayjs());

  const [toimitusosoite1, setToimitusosoite1] = useState(null);
  const [toimitusosoite2, setToimitusosoite2] = useState(null);
  const [vastaanottaja, setVastaanottaja] = useState(null);
  const [huomioitavaa, setHuomioitavaa] = useState(null);

  const [toimituspvm, setToimituspvm] = useState(null);
  const [purkuaika, setPurkuaika] = useState(null);

  const [suunniteltuLastausaika, setSuunniteltuLastausaika] = useState(null);
  const [suunniteltuLastausaikaKlo, setSuunniteltuLastausaikaKlo] = useState(null);

  const [ajoneuvo, setAjoneuvo] = useState(null);
  const [muutpaino, setMuutpaino] = useState(null);


  const getDeliveryVehicles = (stage, name, start, end) => {
    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}getDeliveryVehicles`,
    };

    apiSecure(req_config)
      .then((response) => {
        const { data } = response.data

        let vehArr = data.map((item) => {
          let vehName = `${item?.AutonNimi} ${item?.AutonKantavuus > 0 ? `(${item?.AutonKantavuus} t)` : ""}`
          return ({ label: vehName, value: item.AutonNro })
        })
        setVehicles(vehArr)

        setLoading(false)
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };


  /* Initial data update */
  useEffect(() => {

    if (isOpen) {
      //Jos ajoneuvoja ei ole haettu, haetaan
      if (vehicles.length <= 0) {
        getDeliveryVehicles()
      }

      //Päivitetään toimituksen tiedot
      setToimitusosoite1(deliveryData?.Toimitusosoite1)
      setToimitusosoite2(deliveryData?.Toimitusosoite2)
      setVastaanottaja(deliveryData?.Vastaanottaja)
      setHuomioitavaa(deliveryData?.Teksti)
      setToimituspvm(deliveryData?.ToimitusPVM)
      setSuunniteltuLastausaika(deliveryData?.SuunniteltuLastausaika)
      setSuunniteltuLastausaikaKlo(deliveryData?.SuunniteltuLastausaikaKlo)
      setPurkuaika(deliveryData?.PurkuKlo)
      setAjoneuvo(deliveryData?.AutonTunnus ? deliveryData?.AutonTunnus : null)
      setMuutpaino(deliveryData?.MuutTarvikkeetPaino)
    } else {
      setToimitusosoite1(null)
      setToimitusosoite2(null)
      setVastaanottaja(null)
      setHuomioitavaa(null)
      setToimituspvm(null)
      setSuunniteltuLastausaika(null)
      setSuunniteltuLastausaikaKlo(null)
      setPurkuaika(null)
      setAjoneuvo(null)
      setMuutpaino(null)
      setLoading(false)
    }

  }, [isOpen]);



  /* Tietojen päivitys db */
  const updateDeliveryData = () => {
    setLoading(true)

    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}updateDeliveryData`,
      data: {
        projectId: projectId,
        KKNro: deliveryData.KKNro,
        toimitusosoite1: toimitusosoite1,
        toimitusosoite2: toimitusosoite2,
        vastaanottaja: vastaanottaja,
        huomioitavaa: huomioitavaa,
        slastausaika: suunniteltuLastausaika,
        slastausaikaklo: suunniteltuLastausaikaKlo,
        toimituspvm: toimituspvm,
        purkuaika: purkuaika,
        ajoneuvo: ajoneuvo,
        muutpaino: muutpaino
      },
    };

    console.log('updateDeliveryData', req_config)

    apiSecure(req_config)
      .then((response) => {
        const { status } = response?.data
        closeModal();
        getProject();
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };



  const canDeleteDelivery = () => {
    console.log('canDeleteDelivery');

    return new Promise((resolve, reject) => {
      Modal.confirm({
        title: 'Haluatko varmasti poistaa kuorman',
        okText: 'Kyllä',
        cancelText: 'Ei',
        centered: true,
        onOk: () => {
          resolve(true);  // Kutsutaan resolve, kun "yes" valitaan
        },
        onCancel: () => {
          resolve(false);  // Ratkaistaan lupaus arvoon false, kun "no" valitaan
        }
      });
    });
  };
  const deleteDelivery = async () => {
    console.log('deleteDelivery', deliveryData.Maara)

    //Estetään poistaminen jos Maara kenttää ei ole määritetty tai jos jos Määrä on yli 0
    if (deliveryData.Maara === 0 && !(typeof deliveryData?.Maara === 'undefined' || deliveryData?.Maara === null)) {
      let canDelete = await canDeleteDelivery()
      if (!canDelete) return

      let req_config = {
        method: "POST",
        url: `${envConfig.apiUrlPrefix}deleteDelivery`,
        data: {
          projectId: projectId,
          KKNro: deliveryData.KKNro,
          KuormakirjaNumero: deliveryData.KuormakirjaNumero
        }
      };

      apiSecure(req_config)
      .then((response) => {
        const { status } = response?.data
        closeModal();
        getProject();
      })
      .catch((error) => {
        console.log("Error:", error);
      });
    };
  }

  return (
    <>
      <Modal
        open={isOpen}
        onCancel={() => closeModal()}
        title={`Kuorman muokkaus (${deliveryData.KuormakirjaNumero})`}
        centered={true}
        footer={[
          <Button key="remove" type="primary" danger
            style={{ marginRight: '1em' }}
            onClick={() => {
              deleteDelivery()
            }}
            disabled={deliveryData.Maara >= 1}
            loading={loading}
          >
            Poista
          </Button>,
          <Button key="back" onClick={closeModal}>
            Peruuta
          </Button>,
          <Button key="submit" type="primary" color="red" onClick={() => {
            /* closeModal() */
            updateDeliveryData()
          }}
            loading={loading}>
            Tallenna
          </Button>,
        ]}
      >
        <div>

          <p className="project-modal-label font-dm-medium">{"Toimitusosoite"}</p>
          <Input
            value={toimitusosoite1}
            placeholder="Lähiosoite"
            onChange={(e) => {
              setToimitusosoite1(e.target.value)
            }}
          />
          <Input
            style={{ marginTop: "5px" }}
            value={toimitusosoite2}
            placeholder="Postiosoite"
            onChange={(e) => {
              setToimitusosoite2(e.target.value)
            }}
          />

          <p className="project-modal-label font-dm-medium">{"Vastaanottaja"}</p>
          <Input
            value={vastaanottaja}
            placeholder="Vastaanottaja"
            onChange={(e) => {
              setVastaanottaja(e.target.value)
            }}
          />



          <p className="project-modal-label font-dm-medium">{"Suuunniteltu lastausaika"}</p>
          <DatePicker
            format={'DD.MM.YYYY'}
            showWeek={true}
            className={`font-dm-regular`}
            allowClear={false}
            value={
              suunniteltuLastausaika ? dayjs(suunniteltuLastausaika) : null
            }
            onChange={(
              date
            ) => {
              let saveDate = dayjs(date).format("YYYY-MM-DD") //Tietokantaa varten tähän tmalliin
              setSuunniteltuLastausaika(saveDate)
            }}
          />
          <TimePicker
            style={{ marginLeft: '5px' }}
            format={'HH:mm'}
            value={suunniteltuLastausaikaKlo ? dayjs.utc(suunniteltuLastausaikaKlo) : null}
            onChange={(date) => {
              if (!date) {
                setSuunniteltuLastausaikaKlo(null)
                return
              }

              let saveDate = dayjs(date).format("YYYY-MM-DDTHH:mm:00.000[Z]") //Tietokantaa varten tähän tmalliin
              setSuunniteltuLastausaikaKlo(saveDate)
            }}
          />

          <p className="project-modal-label font-dm-medium">{"Toimitus pvm"}</p>
          <DatePicker
            format={'DD.MM.YYYY'}
            showWeek={true}
            className={`font-dm-regular`}
            allowClear={false}
            minDate={currDate}
            value={
              toimituspvm ? dayjs(toimituspvm) : null
            }
            onChange={(
              date
            ) => {
              let saveDate = dayjs(date).format("YYYY-MM-DD") //Tietokantaa varten tähän tmalliin
              setToimituspvm(saveDate)
            }}
          />
          <TimePicker
            style={{ marginLeft: '5px' }}
            format={'HH:mm'}
            value={purkuaika ? dayjs.utc(purkuaika) : null}
            onChange={(date) => {
              if (!date) {
                setPurkuaika(null)
                return
              }

              let saveDate = dayjs(date).format("YYYY-MM-DDTHH:mm:00.000[Z]") //Tietokantaa varten tähän tmalliin
              setPurkuaika(saveDate)
            }}
          />

          <p className="project-modal-label font-dm-medium">{"Ajoneuvo"}</p>
          <Select
            placeholder={"Valitse ajoneuvo"}
            style={{ minWidth: 200 }}
            options={vehicles}
            allowClear={true}
            value={ajoneuvo}
            onChange={(value) => {
              setAjoneuvo(value)
            }}
          />

          <p className="project-modal-label font-dm-medium">{"Huomioitavaa"}</p>
          <TextArea
            value={huomioitavaa}
            placeholder="Muuta huomioitavaa"
            onChange={(e) => {
              const newValue = e.target.value;
              const newLines = newValue.split('\n').length - 1;

              // Tarkista, että rivinvaihtojen määrä on enintään 1
              if (newLines <= 1) {
                setHuomioitavaa(newValue);
              }
            }}
            autoSize={false}
            rows={2}
            style={{
              resize: 'none',
            }}
            showCount
            maxLength={100}
          />

          <p className="project-modal-label font-dm-medium">{"Muiden tarvikkeiden paino (tonnia)"}</p>
          <InputNumber
            defaultValue={0.0}
            min={0.0}
            max={100.0}
            step={0.1}
            value={muutpaino}
            onChange={(value) => {
              setMuutpaino(value)
            }}
          />
        </div>
      </Modal>
    </>
  );
};
