import { CloseOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";

import { Input, Modal, Button, Select, Checkbox, Switch } from "antd";
import dayjs from "dayjs";
import { useTranslate } from "../../../components/Translate";
import { apiSecure } from "../../../components/api";




export const AddMaterialColumnModal = ({ isOpen, closeModal, currentMaterials, saveFunc }) => {
  console.log("AddMaterialColumnModal data", currentMaterials);
  const [isLoading, setIsLoading] = useState(true);
  const [allMaterials, setAllMaterials] = useState([])
  const [material, setMaterial] = useState(null);
  const [itemizedMaterial, setItemizedMaterial] = useState(false);
  const [defaultFormula, setDefaultFormula] = useState(null);


  const selectRef = useRef(null); // Ref Select-komponentille

  const { locale } = useTranslate();



  const getAllMaterials = async () => {
    setIsLoading(true);


    let req_config = {
      method: "GET",
      url: `intra_getAllExcelMaterials`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response);
        const { allMaterials } = response.data;

        // Muotoillaan tiedot halutuksi arrayksi
        const formattedMaterials = allMaterials
          .filter(material => !currentMaterials.includes(material.TuoteNRO)) // Suodatetaan pois jo lisätyt columnit    
          .map(material => ({
            value: material.TuoteNRO,
            label: `${material.Nimi} `
          }));

        setAllMaterials(formattedMaterials);
        console.log("Formatted Materials:", formattedMaterials);

        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Error:", error);
        setIsLoading(false);
      })
  };


  useEffect(() => {
    if (isOpen) {
      if (allMaterials.length < 1) {
        getAllMaterials()
      }
      setTimeout(() => {
        selectRef.current?.focus(); // Asetetaan fokus Select-komponenttiin
      }, 200); // Pieni viive varmistaa, että modaali on täysin renderöity
    }

  }, [isOpen]);


  const onChange = (value) => {
    console.log(`selected ${value}`);
    setMaterial(value)
  };

  const onSearch = (value) => {
    console.log('search:', value);
  };


  return (
    <>
      <Modal
        open={isOpen}
        onCancel={() => closeModal()}
        title={`Lisää tarvike`}
        centered={true}
        footer={[
          <Button key="submit" type="primary" color="red" onClick={() => {
            closeModal()
            saveFunc(material)
          }}
            loading={isLoading}
          >
            Lisää
          </Button>,
        ]}
      >
        <div className="add-material-modal-item">
          <p className="name font-dm-medium">{"Tuote"}</p>

{/*           <div className="add-material-modal-itemized">
            <Switch
              size="small"
              checked={itemizedMaterial}
              onChange={(checked) => {
                setItemizedMaterial(checked);
              }}
            />
            <p className={`itemized-text ${itemizedMaterial ? 'checked' : ''} font-dm-regular`}>Eriteltävä tarvike</p>
          </div> */}
        </div>

        <Select
          ref={selectRef}
          showSearch
          style={{ width: '100%' }}
          placeholder="Valitse tuote"
          optionFilterProp="label"
          onChange={onChange}
          onSearch={onSearch}
          options={allMaterials}
        />
{/* 
        <p className="project-modal-label font-dm-medium">{"Oletuskaava"}</p>
        <Input
          className="input"
          value={defaultFormula}
          onChange={(e) => setDefaultFormula(e.target.value)}
          maxLength={99}
        />

        <p className="project-modal-label font-dm-medium">{"Hinnoittelu"}</p> */}
      </Modal>
    </>
  );
};
