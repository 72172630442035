import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiSecure } from "../../../components/api";
import { Loading, LoadingItem } from "../../../components/Loading/Loading";

import { useTranslate } from "../../../components/Translate";


import { useMediaQuery } from "react-responsive";
import config, { getEnvSettings } from "../../../config/config";

import Icon, { EditOutlined, PlusOutlined } from '@ant-design/icons';

import { APIProvider, ControlPosition, Map, MapControl, Marker } from '@vis.gl/react-google-maps';
import { EditContactModal } from "./EditContactModal";
import { EditAddressModal } from "./EditAddressModal";
import { EditScheduleModal } from "./EditScheduleModal";
import { EditReceiverModal } from "./EditReceiverModal";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { MapCustomBtns } from "../../../components/mapComponents";
import { MapIcon } from "../../../components/CustomIcons";
import { AddProjectContactModal } from "./AddProjectContactModal";
import { AddContactModal } from "./AddContactModal";
import { openMaps } from "../../../components/globalFunctions";


export const ProjectInfo = ({ }) => {
  const { locale } = useTranslate();
  const envConfig = getEnvSettings();
  let { id } = useParams();
  const navigate = useNavigate();

  const [projectRootData, updateProjLocalData] = useOutletContext();

  const API_KEY = process.env.REACT_APP_MAPS_API_KEY // config.devMapsKey

  const [isLoading, setisLoading] = useState(true);


  const [isAddProjectContactModalOpen, setIsAddProjectContactModalOpen] = useState(false);

  const [isAddContactModalOpen, setIsAddContactModalOpen] = useState(false);


  const [isEditContactModalOpen, setIsEditContactModalOpen] = useState(false);
  const [editContactData, setEditContactData] = useState({});

  const [isEditAddressModalOpen, setIsEditAddressModalOpen] = useState(false);

  const [isEditScheduleModalOpen, setIsEditScheduleModalOpen] = useState(false);
  const [isEditReceiverModalOpen, setIsEditReceiverModalOpen] = useState(false);

  const [isMapReady, setIsMapReady] = useState(false);
  const [coords, setCoords] = useState({ lat: 66.67, lng: 26.86, isDefault: true }); //Defaults to finland 

  const [projectData, setProjectData] = useState([]);


  const getProject = () => {
    setisLoading(true);
    console.log("getProject");

    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}getProjectInfo`,
      data: {
        projectId: id,
      },
    };

    apiSecure(req_config)
      .then((response) => {
        //console.log("RESPONSE", response.data);
        setProjectData(response.data.data);

        setTimeout(() => {
          setisLoading(false);
        }, 1000);

      })
      .catch((error) => {
        console.log("Error:", error);
        setisLoading(false);
      });
  };

  useEffect(() => {
    getProject()
  }, [id]);

  useEffect(() => {
    console.log('projectData222', projectData?.tiedot)
    if (projectData?.tiedot) {
      if (!projectData?.tiedot?.lat || !projectData?.tiedot?.lng) {
        setIsMapReady(true)
      } else {
        var newlat = parseFloat(projectData?.tiedot?.lat.replace(/,/g, "."));
        var newlng = parseFloat(projectData?.tiedot?.lng.replace(/,/g, "."));

        setCoords({ lat: newlat, lng: newlng, isDefault: false })
        setIsMapReady(true)
      }
    }
  }, [projectData]);


  useEffect(() => {
    console.log('coords222', coords)
  }, [coords]);

  const updateAddress = (address1, address2) => {
    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}updateProjectAddress`,
      data: {
        projectId: projectData?.tiedot?.Tyokohdenro,
        address1: address1,
        address2: address2,
      },
    };
    console.log("UPP2", req_config);

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response.data);
        getProject()
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  const updateReceiver = (recText) => {
    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}updateProjectReceiver`,
      data: {
        projectId: projectData?.tiedot?.Tyokohdenro,
        recText: recText,
      },
    };
    console.log("UPP2", req_config);

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response.data);
        getProject()
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  const updateSchedule = (schedText) => {
    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}updateProjectSchedule`,
      data: {
        projectId: projectData?.tiedot?.Tyokohdenro,
        schedText: schedText,
      },
    };
    console.log("UPP2", req_config);

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response.data);
        getProject()

        updateProjLocalData("schedule", schedText)
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };




  return (<div className={`projectpage-content`}>
    <AddProjectContactModal
      isOpen={isAddProjectContactModalOpen}
      closeModal={() => {
        setIsAddProjectContactModalOpen(false);
      }}
      projectId={projectData?.tiedot?.Tyokohdenro}
      refreshData={getProject}
      addedContacts={projectData?.yhteystiedot}
      openAddContact={()=>{
        setIsAddContactModalOpen(true);
        setIsAddProjectContactModalOpen(false);
      }}
    />


    <AddContactModal
      isOpen={isAddContactModalOpen}
      closeModal={() => {
        setIsAddContactModalOpen(false);
      }}
      projectId={projectData?.tiedot?.Tyokohdenro} /* Tätä käytetään vain kun yhteistieto lisätään myös suoraaan projektille */
      refreshData={getProject}
    />

    <EditContactModal
      isOpen={isEditContactModalOpen}
      closeModal={() => {
        setIsEditContactModalOpen(false);
      }}
      projectId={projectData?.tiedot?.Tyokohdenro}
      data={editContactData}
      refreshData={getProject}
    />

    <EditAddressModal
      isOpen={isEditAddressModalOpen}
      closeModal={() => {
        setIsEditAddressModalOpen(false);
      }}
      data={{
        address1: projectData?.tiedot?.Toimitusosoite1,
        address2: projectData?.tiedot?.Toimitusosoite2,
      }}
      saveFunc={updateAddress}
    />


    <EditScheduleModal
      isOpen={isEditScheduleModalOpen}
      closeModal={() => {
        setIsEditScheduleModalOpen(false);
      }}
      data={{
        schedule: projectData?.tiedot?.Aikataulu,
      }}
      saveFunc={updateSchedule}
    />

    <EditReceiverModal
      isOpen={isEditReceiverModalOpen}
      closeModal={() => {
        setIsEditReceiverModalOpen(false);
      }}
      data={{
        receiver: projectData?.tiedot?.Vastaanottaja,
      }}
      saveFunc={updateReceiver}
    />


    <div className="project-info">
      <p className="project-info-title font-dm-bold">Projektin tiedot</p>

      {!isLoading ? <>

        <div className="project-info-box">
          {/* YHTEYSTIEDOT */}
          <div className="project-info-contacts">
            <p className="project-info-box-header font-dm-bold">Yhteystiedot</p>

            {projectData?.yhteystiedot?.length >= 1 && projectData?.yhteystiedot?.map((contact) => {

              console.log('contact', contact)

              if (contact.OnkoTilaaja) {
                contact.Toimenkuva = "Tilaaja"
              }

              return (
                <div className="project-info-contacts-item" key={contact?.YhteysNRO}>
                  <div className="project-info-row">
                    <p className={`project-info-contacts-item-header ${contact?.Toimenkuva ? 'font-dm-regular' : 'font-dm-bold'}`}>{contact?.Toimenkuva ? contact?.Toimenkuva : contact?.Henkilo}</p>
                    <div className="project-info-edit project-info-marginleft" onClick={() => {
                      console.log('contact', contact);
                      setEditContactData(contact)
                      setIsEditContactModalOpen(true)
                    }}>
                      <EditOutlined />
                    </div>
                  </div>

                  {contact?.Toimenkuva ? <p className="font-dm-bold">{contact?.Henkilo ? contact?.Henkilo : contact?.Yritys}</p> : null}
                  <p className="font-dm-regular">{contact?.GSM}</p>
                  <p className="font-dm-regular">{contact?.Sahkoposti}</p>
                </div>
              )

            })}


            <div className="project-info-contacts-add" onClick={() => {
              setIsAddProjectContactModalOpen(true);
            }}>
              <PlusOutlined className="project-info-contacts-addIcon" />
            </div>
          </div>



          <div className="project-info-box2-inner">

            {/* TOIMITUS */}
            <div className="project-info-delivery">
              <p className="project-info-box-header font-dm-bold">Toimitus</p>

              <div className="project-info-row">
                <div className="project-info-delivery-item">
                  <div className="project-info-row">
                    <p className="project-info-delivery-item-header font-dm-regular">Toimitusosoite</p>
                    <div className="project-info-edit" onClick={() => {
                      setIsEditAddressModalOpen(true);
                    }}>
                      <EditOutlined />
                    </div>
                  </div>
                  <p className="project-info-delivery-item-value font-dm-bold">{projectData?.tiedot?.Toimitusosoite1 ? projectData?.tiedot?.Toimitusosoite1 : ''}</p>
                  <p className="project-info-delivery-item-value font-dm-bold">{projectData?.tiedot?.Toimitusosoite2 ? projectData?.tiedot?.Toimitusosoite2 : ''}</p>
                </div>

                <div className="project-info-delivery-item">
                  <div className="project-info-row">
                    <p className="project-info-delivery-item-header font-dm-regular">Vastaanottaja</p>
                    <div className="project-info-edit" onClick={() => { setIsEditReceiverModalOpen(true); }}>
                      <EditOutlined />
                    </div>
                  </div>
                  <p className="project-info-delivery-item-value font-dm-bold">{projectData?.tiedot?.Vastaanottaja ? projectData?.tiedot?.Vastaanottaja : ''}</p>
                </div>
              </div>



              <div className="project-info-delivery-map">
                {isMapReady ?
                  <APIProvider apiKey={API_KEY}>
                    <Map
                      id={"map-custom-id"}
                      style={{ width: '100%', height: '100%' }}
                      defaultCenter={{ lat: coords?.lat, lng: coords?.lng }}
                      defaultZoom={coords?.isDefault ? 3 : 10}
                      gestureHandling={'greedy'}
                      disableDefaultUI={true}

                    >



                      <MapCustomBtns id={"map-custom-id"} />


                      {!coords?.isDefault &&
                        <MapControl position={ControlPosition.INLINE_END_BLOCK_END}>
                          <button className="project-info-delivery-map-btn font-dm-regular clickable" onClick={() => {
                            openMaps(coords)
                          }}>
                            <MapIcon className="project-info-delivery-map-btn-icon" />
                            Näytä kartalla</button>
                        </MapControl>
                      }


                      {!coords?.isDefault && <Marker position={{ lat: coords?.lat, lng: coords?.lng }} />}
                    </Map>
                  </APIProvider>
                  : null}
              </div>

            </div>

            {/* AIKATAULU */}
            <div className="project-info-schedule">
              <p className="project-info-box-header font-dm-bold">Aikataulu</p>
              <div className="project-info-row">
                <p className="project-info-schedule-value font-dm-regular">{projectData?.tiedot?.Aikataulu ? projectData?.tiedot?.Aikataulu : '-'}</p>
                <div className="project-info-edit project-info-marginleft" onClick={() => { setIsEditScheduleModalOpen(true); }}>
                  <EditOutlined />
                </div>
              </div>
            </div>
          </div>
        </div>
      </> : <div className="project-info-loading">
        <LoadingItem color="#000" height={'2vh'} width={'2vh'} />
      </div>}
    </div>
  </div>);
};
