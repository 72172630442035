import { FC, useEffect, useRef, useState } from "react";
import { CellWrapper } from "@silevis/reactgrid";
import { Button, Modal, Tooltip } from "antd";

function parseMathNotation(text) {
  return text.split(/([_{].*?}|[\^].*?)/g).map((part, index) => {
    if (part.startsWith("_{") && part.endsWith("}")) {
      return <sub key={index}>{part.slice(2, -1)}</sub>;
    }
    return part;
  });
};

export const HeaderCell = ({ value, id, hover, style, topLeft, topRight,clickDeleteMaterial }) => {
  //console.log('value', value)
  let nValue = parseMathNotation(value)

  return (
    <Tooltip title={hover}>
      <CellWrapper onStringValueRequested={() => nValue?.toString() || ""} onStringValueReceived={() => { }} style={style} id={topLeft ? "topLeft" : topRight ? "topRight" : ""}
        onDoubleClick={() => {

          if (id.includes("tarvike_")) {
            console.log('onDoubleClick', id)
            Modal.confirm({
              title: 'Haluatko poistaa sarakkeen',
              content: `${nValue}`,
              okText: 'Kyllä',
              cancelText: 'Ei',
              centered: true,
              onOk: () => {
                clickDeleteMaterial()
                // Kutsutaan resolve, kun "yes" valitaan
              },
              onCancel: () => {
                // Ratkaistaan lupaus arvoon false, kun "no" valitaan
              }
            });
          }
        }}
      >
        <div className={"header-text"} >
          {nValue}
        </div>
      </CellWrapper>
    </Tooltip>
  );
};