import { FC, useEffect, useRef, useState } from "react";
import { CellWrapper, useCellContext, useDoubleTouch } from "@silevis/reactgrid";
import { Dropdown } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

const items = [
  {
    label: 'Poista rivi',
    key: 'delete',
    icon: <CloseCircleOutlined />,
    danger: true,
  },
];

export const ElementNameCell = ({ text: initialText, onTextChanged, style, status, menuOnClick, isNew = false, defaultIdPrefix }) => {
  const ctx = useCellContext();
  const targetInputRef = useRef(null);
  const [isEditMode, setEditMode] = useState(false);
  const [currentValue, setCurrentValue] = useState(initialText || "");
  const escapePressedRef = useRef(false);
  const { handleDoubleTouch } = useDoubleTouch(ctx, setEditMode);

  useEffect(() => {
    setCurrentValue(initialText);
  }, [initialText]);

  return (
    <CellWrapper
      onStringValueRequested={() => initialText}
      onStringValueReceived={(v) => onTextChanged?.(v)}
      onTouchEnd={handleDoubleTouch}
      style={{
        padding: 0,
        alignItems: 'unset',
        justifyContent: 'unset'
      }}
      onDoubleClick={() => {
        if (ctx.isFocused) {
          setCurrentValue(isNew ? defaultIdPrefix : (initialText || ""));
          setEditMode(true);
        }
      }}
      onKeyDown={(e) => {
        if (!isEditMode && /^[a-zA-Z0-9=]$/.test(e.key)) {
          e.stopPropagation();
          setCurrentValue(isNew ? defaultIdPrefix : "");
          setEditMode(true);
        } else if (!isEditMode && !ctx.isSelected && (e.key === "Enter" || e.key === "F2")) {
          e.stopPropagation();
          setCurrentValue(isNew ? defaultIdPrefix : (initialText || ""));
          setEditMode(true);
        }
      }}
    >
      {isEditMode ? (
        <input
          className="rg-input"
          value={currentValue}
          onChange={(e) => setCurrentValue(e.currentTarget.value)}
          onBlur={(e) => {
            if (!escapePressedRef.current) {
              onTextChanged?.(e.currentTarget.value);
            }
            setEditMode(false);
            if (escapePressedRef.current) {
              escapePressedRef.current = false;
            }
          }}
          onCut={(e) => e.stopPropagation()}
          onCopy={(e) => e.stopPropagation()}
          onPaste={(e) => e.stopPropagation()}
          onPointerDown={(e) => e.stopPropagation()}
          onKeyDown={(e) => {
            const controlKeys = ["Escape", "Enter", "Tab"];
            if (!controlKeys.includes(e.key)) {
              e.stopPropagation();
            }
            if (e.key === "Escape") {
              escapePressedRef.current = true;
              setEditMode(false);
            } else if (e.key === "Enter") {
              setEditMode(false);
            }
          }}
          autoFocus
          ref={targetInputRef}
        />
      ) : (


        <div className="grid-element-name">
          <Dropdown
            menu={{ items: items, onClick: menuOnClick }}
            trigger={['contextMenu']}
            placement="bottomRight"
            disabled={isNew}
/*             getPopupContainer={() => document.getElementById('react-grid-container')}
 */          >
            <div className="name-left" style={status === "saving"
              ? { backgroundColor: "yellow" }
              : status === "saved"
                ? { backgroundColor: "#EFEFEF" }
                : status === "error"
                  ? { backgroundColor: "red" }
                  : { backgroundColor: "orange" }}>
            </div>
          </Dropdown>

          {!isNew ?
            <div className="name-right">
              {initialText}
            </div>
            :
            <div className="name-right prefix">
              {defaultIdPrefix}
            </div>
          }
        </div>
      )}
    </CellWrapper>
  );
};