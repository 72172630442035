import { useEffect, useRef, useState, useMemo } from "react";
import "./Searchbar.css";
import { SearchOutlined, ContactsOutlined, HomeOutlined, UserAddOutlined } from "@ant-design/icons";
import { useTranslate } from "../Translate";
import { Input } from "antd";
import { useDispatch } from "react-redux";
import AllActions from "../../store/actions";
import { useSelector } from "react-redux";
import { getEnvSettings } from "../../config/config";
import { apiSecure } from "../api";

import { LoadingItem } from "../Loading/Loading";
import { useNavigate } from "react-router-dom";

import { debounce } from 'lodash';
import { AddContactModal } from "../../pages/Project/ProjectInfo/AddContactModal";


const highlightText = (text, highlight) => {
  if (!highlight) return text;
  if (!text) return '';

  // Pakenee kaikki säännöllisen lausekkeen erikoismerkit
  const escapedHighlight = highlight.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
  const regex = new RegExp(`(${escapedHighlight})`, 'gi');
  return text.replace(regex, '<span class="font-dm-bold">$1</span>');
};



const RenderContacts = ({items, activeIndex, searchValue, closeSearch, setIsAddContactModalOpen}) => {
  return (
    <>
      <div className="searchbar-results-title-row">
        <p className="searchbar-results-title font-dm-medium">
          <ContactsOutlined /> Yhteystiedot
        </p>
        <p
          className="searchbar-results-add clickable"
          onClick={() => {
            closeSearch();
            setIsAddContactModalOpen(true);
          }}
        >
          <UserAddOutlined />
        </p>
      </div>

      {items.map((item, index) => {
        const isActive = activeIndex.listName === 'contacts' && activeIndex.index === index;

        return (
          <div
            key={item.id}
            className={`searchbar-results-contact font-dm-regular clickable ${isActive ? 'result-active' : ''
              }`}
            onClick={item.action}
          >
            <p
              className="searchbar-results-contact-name"
              dangerouslySetInnerHTML={{
                __html: highlightText(item.mainText, searchValue),
              }}
            />
            <div style={{ display: 'flex', flexDirection: 'row', minHeight: '16px' }}>
              <p
                dangerouslySetInnerHTML={{
                  __html: highlightText(item.subText, searchValue),
                }}
                className="searchbar-results-contact-company"
              />
              <p
                dangerouslySetInnerHTML={{
                  __html: highlightText(item.gsm, searchValue),
                }}
                className="searchbar-results-contact-gms"
              />
            </div>
          </div>
        );
      })}
    </>
  );
};


const RenderProjects = ({items, activeIndex, searchValue}) => {
  return (
    <>
      <div className="searchbar-results-title-row">
        <p className="searchbar-results-title font-dm-medium">
          <HomeOutlined /> Työkohteet
        </p>
      </div>

      {items.map((item, index) => {
        const isActive = activeIndex.listName === 'projects' && activeIndex.index === index;

        return (
          <div
            key={item.id}
            className={`searchbar-results-project font-dm-regular clickable ${isActive ? 'result-active' : ''
              }`}
            onClick={item.action}
          >
            <p
              dangerouslySetInnerHTML={{
                __html: highlightText(item.mainText, searchValue),
              }}
              className="searchbar-results-project-name"
            />
            <p
              className="searchbar-results-project-status"
              style={{
                color: item.color,
                borderColor: item.color,
              }}
            >
              {item.subText}
            </p>
          </div>
        );
      })}
    </>
  );
};


export const Searchbar = () => {
  const dispatch = useDispatch();
  const { locale } = useTranslate();
  const envConfig = getEnvSettings();
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState('');

  const searchInputRef = useRef(null);
  const dropdownRef = useRef(null);

  const { showSearch } = useSelector((state) => state.currentUser);

  const [isLoading, setIsLoading] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);


  const [contactsArr, setContactsArr] = useState([]);
  const [projectsArr, setProjectsArr] = useState([]);

  const [filteredContacts, setFilteredContacts] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);

  const [isAddContactModalOpen, setIsAddContactModalOpen] = useState(false);

  const [activeIndex, setActiveIndex] = useState(-1);


  // Normalisoi hakutermi vain puhelinnumeroita varten
  const normalizePhoneNumber = value => value.replace('+358', '0').replace(/\s|-/g, '');
  const normalizedSearchValue = useMemo(() => searchValue.toLowerCase(), [searchValue]);

  // Yhteinen suodatusfunktio kontakteille ja projekteille
  const filterContacts = contact => {
    const henkiloMatch = contact?.Henkilo?.toLowerCase().includes(normalizedSearchValue);
    const yritysMatch = contact?.Yritys?.toLowerCase().includes(normalizedSearchValue);
    const gsmMatch = contact?.GSM && normalizePhoneNumber(contact.GSM).includes(normalizePhoneNumber(normalizedSearchValue));

    return henkiloMatch || yritysMatch || gsmMatch;
  };

  const filterProjects = project => {
    const tyokohdeMatch = project?.Tyokohde?.toLowerCase().includes(normalizedSearchValue);
    const tyokohdenroMatch = project?.Tyokohdenro?.toString().includes(normalizedSearchValue);
    const henkiloMatch = project?.Henkilo?.toLowerCase().includes(normalizedSearchValue);
    const yritysMatch = project?.Yritys?.toLowerCase().includes(normalizedSearchValue);

    const liitetytHenkilot = project?.LiitetytHenkilot ? JSON.parse(project.LiitetytHenkilot) : [];
    const liitetytHenkilotMatch = liitetytHenkilot.some(liitetty =>
      liitetty?.Henkilo?.toLowerCase().includes(normalizedSearchValue) ||
      liitetty?.Yritys?.toLowerCase().includes(normalizedSearchValue)
    );

    return tyokohdeMatch || tyokohdenroMatch || henkiloMatch || yritysMatch || liitetytHenkilotMatch;
  };

  const debouncedFilter = useMemo(() => debounce(() => {
    if (contactsArr.length <= 0 || projectsArr.length <= 0) return;
    if (!searchValue || searchValue.length <= 2) {
      setFilteredContacts([]);
      setFilteredProjects([]);
      return;
    }

    const nContacts = contactsArr.filter(filterContacts);
    const nProjects = projectsArr.filter(filterProjects);

    setFilteredContacts(nContacts);
    setFilteredProjects(nProjects);
  }, 300), [contactsArr, projectsArr, normalizedSearchValue]);

  useEffect(() => {
    debouncedFilter();
    setActiveIndex(-1)
    return () => {
      debouncedFilter.cancel();
    };
  }, [searchValue, contactsArr, projectsArr, debouncedFilter]);

  //Input focus
  useEffect(() => {
    if (!showSearch) {
      setSearchValue('')
    }
  }, [showSearch]);

  //Input focus
  useEffect(() => {
    if (searchInputRef.current && showSearch) {
      searchInputRef.current.focus();
    }
  }, [searchInputRef, showSearch, isLoading]);


  const closeSearch = () => {
    dispatch(AllActions.userActions.updateShowSearch(true));

  }

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
/*       console.log('CLICK OUTSIDE')
 */      closeSearch()
    } else {
/*       console.log('CLICK INSIDE')
 */    }
  }
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, []);

  const getSearchData = () => {
    console.log("getSearchData");

    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}getSearchData`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response);
        setDataLoaded(true)
        setContactsArr(response?.data?.data?.yhteystiedot)
        setProjectsArr(response?.data?.data?.tyokohteet)
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Error:", error);
        //setisLoading(false);
      });
  };

  useEffect(() => {
    if (!showSearch || dataLoaded) return
    getSearchData()
  }, [showSearch]);



  const openContact = (item) => {
    navigate(`/contact/${item.YhteysNRO}`);
    closeSearch()
  }


  const openProject = (item) => {
     console.log('openProject', item)

    //Päivitetään tehtaan state:
/*     if (item?.Tehdas) {
      console.log('TEHTAAN PÄIVITYS', item?.Tehdas)
      dispatch(AllActions.userActions.updateFactory(item?.Tehdas));
    } */
    if (item?.Tila === 3 || item?.Tila === 5) {
      navigate(`/project/${item.Tyokohdenro}`);
    } else {
      navigate(`/quotation/${item.Tyokohdenro}`);
    }

    closeSearch()
  }

  const allResults = useMemo(() => [
    {
      type: 'contacts',
      title: 'Yhteystiedot',
      render: (itemsTest,activeIndexTest,searchValueTest) => <RenderContacts items={itemsTest} activeIndex={activeIndexTest} searchValue={searchValueTest} closeSearch={closeSearch} setIsAddContactModalOpen={setIsAddContactModalOpen}/>,
      items: filteredContacts.map((itm) => ({
        id: itm.YhteysNRO,
        mainText: itm.Henkilo?.trim() || itm.Yritys,
        subText: itm.Henkilo?.trim() ? itm.Yritys : '',
        gsm: itm.GSM,
        action: () => openContact(itm),
      })),
    },
    {
      type: 'projects',
      title: 'Työkohteet',
      render:  (itemsTest,activeIndexTest,searchValueTest) => <RenderProjects items={itemsTest} activeIndex={activeIndexTest} searchValue={searchValueTest} />,
      items: filteredProjects.map((itm) => ({
        id: itm.Tyokohdenro,
        mainText: `${itm.Tyokohdenro} ${itm.Tyokohde}`,
        subText: itm.TilaNimi,
        color: itm.Varikoodi,
        action: () => openProject(itm),
      })),
    },
  ], [filteredContacts, filteredProjects]);


  const flatResults = useMemo(() => {
    return allResults.flatMap((list) =>
      list.items.map((item, index) => ({
        listName: list.type,
        index,
        item,
        action: item.action, // Navigoinnin toiminto
      }))
    );
  }, [allResults]);


  useEffect(() => {
    const handleKeyDown = (e) => {
      if (!showSearch || flatResults.length === 0) return;
  
      const currentIndex = flatResults.findIndex(
        (entry) => entry.listName === activeIndex.listName && entry.index === activeIndex.index
      );
  
      if (e.key === 'ArrowDown') {
        // Siirry seuraavaan kohteeseen
        const nextIndex = (currentIndex + 1) % flatResults.length;
        const nextEntry = flatResults[nextIndex];
        setActiveIndex({ listName: nextEntry.listName, index: nextEntry.index });
      } else if (e.key === 'ArrowUp') {
        // Siirry edelliseen kohteeseen
        const prevIndex = (currentIndex - 1 + flatResults.length) % flatResults.length;
        const prevEntry = flatResults[prevIndex];
        setActiveIndex({ listName: prevEntry.listName, index: prevEntry.index });
      } else if (e.key === 'Enter' && currentIndex >= 0) {
        // Aktivoi valittu kohde
        flatResults[currentIndex].action();
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [flatResults, activeIndex, showSearch]);

  console.log('activeIndex', activeIndex)

  useEffect(() => {
    // Etsi aktiivinen elementti DOM:ista
    const activeElement = document.querySelector('.result-active');
    console.log('activeElement', activeElement)

    if (activeElement) {
      activeElement.scrollIntoView({
        behavior: 'smooth', // Smooth vieritys
        block: 'center',   // Vieritys lähimpään näkyvään kohtaan
      });
    }
  }, [activeIndex]);


  return (
    <>

      <AddContactModal
        isOpen={isAddContactModalOpen}
        closeModal={() => {
          setIsAddContactModalOpen(false);
        }}
      />

      {showSearch ?
        <>
          {/* Blur overlay */}
          <div className="blur-overlay" />

          <div className="searchbar" ref={dropdownRef}>
            <div className="searchbar-inside">

              <div className="searchbar-inside-row">
                <div className="searchbar-icon-box">

                  <SearchOutlined className="searchbar-icon" />
                </div>

                {!isLoading ?

                  <Input
                    ref={searchInputRef}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    placeholder={locale.search}
                    className="searchbar-input font-dm-regular"
                  /> : <div style={{ marginLeft: 10, marginBottom: 10, marginTop: 5 }}>
                    <LoadingItem color="#fff" /> </div>}
              </div>


              {searchValue.length >= 3 && ((filteredContacts.length >= 1) || (filteredProjects.length >= 1)) ?
                <div className="searchbar-results">

                    {allResults.map((list) => (
                      <div key={list.type} className={`searchbar-results-${list.type}`}>
                        {list.render(list.items, activeIndex, searchValue)}
                      </div>
                    ))}

                </div>
                : <></>}
            </div>
          </div>
        </>
        : <></>}
    </>
  );
};
