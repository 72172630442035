import { useState } from "react";

export const EditText = ({ className, text, maxLength, onUpdate}) => {
  const [isEditing, setIsEditing] = useState(false); // Muokkaustila
  const [newText, setNewText] = useState(text); // Otsikon tila

  // Tallenna muutos
  const handleSave = async () => {
    setIsEditing(false); // Poistu muokkaustilasta

    if (newText !== text) {
      onUpdate(newText)
    }
  };

  // Tallenna, kun kenttä menettää fokuksen (onBlur)
  const handleBlur = () => {
    handleSave();
  };

  // Päivitä tila, kun käyttäjä tekee muutoksia
  const handleChange = (e) => {
    setNewText(e.target.value);
  };

  return <>
    {isEditing ? (
      <input
        type="text"
        className={className}
        value={newText}
        onChange={handleChange}
        onBlur={handleBlur} // Kun menettää fokuksen, tallennetaan
        onKeyDown={(e) => {
          if (e.key === 'Enter') handleSave(); // Tallennetaan, kun painetaan Enter
        }}
        autoFocus
        maxLength={maxLength}
      />
    ) : (
      <p className={className} onDoubleClick={() => setIsEditing(true)}>
        {text}
      </p>
    )}
  </>
}
