import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loading } from "../../components/Loading/Loading";
import AllActions from "../../store/actions";
import "./Login.css";

import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import { useTranslate } from "../../components/Translate";

import logo2 from "../../assets/images/ybtlogosmall.png";
import { LanguageSwitcher } from "../../components/LanguageSwitcher/LanguageSwitcher";
import { getEnvSettings } from "../../config/config";


export const Login = ({ setToken }) => {
  const dispatch = useDispatch();
  const { locale } = useTranslate();

  const envConfig = getEnvSettings();


  const [isLoading, setisLoading] = useState(false);
  const { response_type, loggingIn } = useSelector(
    (state) => state.currentUser
  );

  const [alertMessage, setAlertMessage] = useState(false);

  useEffect(() => {
    if (response_type === "error") {
      setAlertMessage(true);
    } else {
      setAlertMessage(false);
    }
  }, [response_type]);

  if (isLoading) {
    return <Loading />;
  }

  const handleSubmit = async (values) => {
    //console.log("handleSubmit 1");
    if (loggingIn) return;
    //console.log("Received values of form: ", values);

    dispatch(
      AllActions.userActions.getUser({
        username: values.username,
        password: values.password,
      })
    );
  };

  window.addEventListener("load", (event) => {
    console.log("page is fully loaded");
  });

  return (
    <>
      <div className="login-wrapper">
        <div className="login-image-wrapper"></div>

        <div className="login-form-wrapper">
          <div className="login-form-content">
            <img
              className=""
              src={logo2}
              width={80}
              alt="YBT Oy"
              onClick={() => {
                window.location = "/";
              }}
              href="someLink"
            />


            <h1 className="login-header font-itc">{locale.slogan}</h1>


            <div className="login-namediv">
              <p className="login-appname font-dm-regular">{envConfig.title}</p>
              <span style={{marginLeft:"0.5em"}}>-</span>
              <div className="login-language">
                <LanguageSwitcher additionalClasses={"header-language"} />
              </div>
            </div>



            <Form
              name="normal_login"
              className="login-form"
              initialValues={{
                remember: true,
                username: "",
                password: "",
              }}
              onFinish={handleSubmit}
            >
              {alertMessage && <p className="errorText">{locale.loginerror}</p>}

              <Form.Item
                name="username"
                rules={[{ required: true, message: locale.insertusername }]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder={locale.username}
                  style={{ borderRadius: "10px" }}
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: locale.insertpassword }]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder={locale.password}
                  style={{ borderRadius: "10px" }}
                />
              </Form.Item>

              <div className="">
                <p>
                  {locale.termstext} <a href="">{locale.terms}</a> {locale.and}
                  {"  "}
                  <a>{locale.privacy}</a>
                </p>
              </div>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button2"
                >
                  {loggingIn ? locale.loginbtnprogress : locale.loginbtn}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};
