import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { apiSecure } from "../../components/api";
import { Loading, } from "../../components/Loading/Loading";
import "./Project.css";

import {
  FileOutlined,
  InfoCircleFilled,
  HomeFilled
} from "@ant-design/icons";

import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslate } from "../../components/Translate";
import { useMediaQuery } from "react-responsive";
import { getEnvSettings } from "../../config/config";
import { BackBtn } from "../../components/BackBtn/BackBtn";
import { BusinessIcon } from "../../components/CustomIcons";
import AllActions from "../../store/actions";
import { useSelector } from "react-redux";



export const Project = ({ setToken }) => {
  const [isLoading, setisLoading] = useState(true);
  const [projectRootData, setProjectRootData] = useState({});
  const dispatch = useDispatch();

  const { locale } = useTranslate();
  let { id } = useParams();


  const navigate = useNavigate();
  const envConfig = getEnvSettings();

  const isMobile = useMediaQuery({ maxWidth: 500 });
  const isMediumWidth = useMediaQuery({ maxWidth: 1100 });

  const [screen, setScreen] = useState("elements"); // elements, notes, info
  const location = useLocation();

  const [animationRoot, setAnimationRoot] = useState(""); // elements, notes, info

  const [initialFactoryDone, setInitialFactoryDone] = useState(0); // elements, notes, info
  const { selectedFactory } = useSelector((state) => state.currentUser.user);
  console.log('animationRoot', animationRoot)

  useEffect(() => {
    const currPath = location.pathname
    //const lastSegment = currPath.split('/').filter(Boolean).pop();

    if (currPath.includes("note")) {
      setScreen("notes")
      return
    }

    if (currPath.includes("info")) {
      setScreen("info")
      return
    }
    setProjectRootData({})
    setScreen("elements");
  }, [id]); // 


  //Projektin tiedoista otetaan tehdas ja asetetaan se tehtaaksi sovelluksessa
  useEffect(() => {
    console.log('projectRootData?.tiedot?.tehdas', projectRootData?.tiedot?.Tehdas)
    let initFactory = projectRootData?.tiedot?.Tehdas
    if (initFactory && !initialFactoryDone) {
      setInitialFactoryDone(initFactory)
      console.log('TEHTAAN PÄIVITYS', initFactory)
      dispatch(AllActions.userActions.updateFactory(initFactory));
    }
  }, [projectRootData]);

  //Jos tehdasta muutetaan manuaalisesti, mennään projektit sivulle
  useEffect(() => {
    console.log('Changed Factory:', selectedFactory, initialFactoryDone)
    if (initialFactoryDone && selectedFactory != initialFactoryDone) {
      navigate("/projects/")
    }
  }, [selectedFactory]);







  /* Other BEGINS */
  const getProject = () => {
    setisLoading(true);
    console.log("getProject");

    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}getProjectRoot`,
      data: {
        projectId: id,
      },
    };

    apiSecure(req_config)
      .then((response) => {
        setProjectRootData(response.data.data);

        setTimeout(() => {
          setisLoading(false);
        }, 100);
      })
      .catch((error) => {
        console.log("Error:", error);
        setisLoading(false);
      });
  };



  const updateProjLocalData = (type, value) => {
    console.log("updateProjLocalData", type, value);

    if (type === 'schedule') {
      setProjectRootData((prevData) => ({
        ...prevData,
        tiedot: {
          ...prevData.tiedot,
          Aikataulu: value,
        },
      }));
    }

  };


  useEffect(() => {
    const isValidId = !isNaN(Number(id));
    if (!isValidId) {
      console.error("Virheellinen ID:", id);
      navigate("/error");
    } else {
      getProject()
    }
  }, [id]);


  if (isLoading) {
    return <Loading />;
  }

  if (!projectRootData.tiedot) {
    return (
      <div className="projects-nodata">
        <p className="projects-nodata-text font-dm-bold">Projektia ei löydy tai haussa ilmeni ongelmia!</p>
      </div>
    );
  }

  console.log('location?.state', location?.state)

  return (
    <>
      <div className="projectmain" id="projectmain">
        <div className={`projectpage-content ${animationRoot}`}>
          <div className={`project-header-12 `}>
            <BackBtn style={{ marginBottom: 5 }} onClick={() => {
              if (location?.state?.from === "/contact") {
                navigate(-1);
              } else {
                navigate('/projects'); // Korvaa '/pääsivu' haluamallasi pääsivun URLilla
              }
            }} />

            <div className="project-header-smallrow">
              <p className="project-header-id font-dm-bold">
                {projectRootData.tiedot.Tyokohdenro}
              </p>

              <p className="project-header-schedule font-dm-bold">
                {projectRootData.tiedot.Aikataulu}
              </p>
            </div>

            <h1 className="project-header font-itc">
              {projectRootData.tiedot.Tyokohde}
            </h1>
            <div className="project-header-headeritems">
              <div className="item">
                <p className="label font-dm-regular">{locale.ordered}</p>
                <p className="value font-dm-bold">
                  {projectRootData.tiedot.TilattuKplYht || 0}
                </p>
              </div>

              <div className="item">
                <p className="label font-dm-regular">{locale.blueprints}</p>
                <p className="value font-dm-bold">
                  {projectRootData.tiedot.PiirustuksiaKplYht || 0}
                </p>
              </div>

              <div className="item">
                <p className="label font-dm-regular">{locale.prepared}</p>
                <p className="value font-dm-bold">
                  {projectRootData.tiedot.ValmistettuKplYht || 0}
                </p>
              </div>

              <div className="item">
                <p className="label font-dm-regular">{locale.booked}</p>
                <p className="value font-dm-bold">
                  {projectRootData.tiedot.ToimitettuKplYht || 0}
                </p>
              </div>

              {!isMobile ?
                <div className="items-right">
                  <div className="screen-buttons">
                    <div className={`screen-button ${screen === 'elements' ? 'selected' : ''}`} onClick={() => {
                      setScreen("elements")
                      navigate('')
                    }}>
                      <BusinessIcon />
                    </div>
                    {envConfig.env === "intra" && <>
                      <div className={`screen-button ${screen === 'notes' ? 'selected' : ''}`} onClick={() => {
                        setScreen("notes")
                        navigate('notes')
                      }}>
                        <FileOutlined style={{ fontSize: 20 }} />
                      </div>
                      <div className={`screen-button ${screen === 'info' ? 'selected' : ''}`} onClick={() => {
                        setScreen("info")
                        navigate('info')
                      }}>
                        <InfoCircleFilled style={{ fontSize: 20 }} />
                      </div>
                    </>
                    }
                  </div>
                </div>
                : <></>}
            </div>
          </div>

          {/* Child routes render */}
          <Outlet context={[projectRootData, updateProjLocalData, setAnimationRoot]} />
        </div>
      </div>
    </>
  );
};
