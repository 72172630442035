import { CloseOutlined, SearchOutlined, UserAddOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";

import { Input, Modal, Button, Select } from "antd";
import dayjs from "dayjs";
import { useTranslate } from "../../components/Translate";
import { apiSecure } from "../../components/api";
import { getEnvSettings } from "../../config/config";
import { LockOutlined, UnlockOutlined } from '@ant-design/icons';

const ModalTextItem = ({ label, value, setValue, disabled = false, canLock = false, locked, setLocked, status = "", maxLength }) => {
  const { locale } = useTranslate();

  return (<div>
    <p className="project-modal-label font-dm-medium">{label}</p>
    <Input
      value={value}
      disabled={locked || disabled}
      onChange={(e) => {
        setValue(e.target.value)
        console.log("onChange", e.target.value)
      }}
      status={status}
      addonAfter={canLock ? <>{locked ? <LockOutlined onClick={() => { setLocked(false) }} /> : <UnlockOutlined onClick={() => { setLocked(true) }} />}</> : null}
      maxLength={maxLength}
    />
  </div>
  );
};

export const AddQuotationCalcElementgroupModal = ({ isOpen, closeModal, calcId, refreshData }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { locale } = useTranslate();
  const envConfig = getEnvSettings();
  const [allTypes, setAllTypes] = useState([])
  const [selectedElementType, setSelectedElementType] = useState(null)
  const selectedElementTable = allTypes?.find(itm => itm.value == selectedElementType)
  const [elementText, setElementText] = useState("")
  const [surfacesText, setSurfacesText] = useState("")

  const [allShapes, setAllShapes] = useState([])
  const [selectedShape, setSelectedShape] = useState(null)

  const getElementTypes = async () => {
    setIsLoading(true);
    let req_config = {
      method: "GET",
      url: `intra_getQuotElementTypes`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response);
        const { types, shapes } = response.data;

        // Muotoillaan tiedot halutuksi arrayksi
        const formattedTypes = types.map(elemType => ({
          value: elemType.TyyppiNro,
          table: elemType.LaskentaTaulukko,
          label: `${elemType.Selite} (${elemType.LaskentaTaulukko})`
        }));
        setAllTypes(formattedTypes);

        const formattedShapes = shapes.map(shape => ({
          value: shape.MuotoId,
          label: `${shape.Muoto}`
        }));
        setAllShapes(formattedShapes);

        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Error:", error);
        setIsLoading(false);
      })
  };

  const saveData = async () => {
    setIsLoading(true);

    let req_config = {
      method: "POST",
      url: `intra_addQuotationCalcElementgroup`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        calcId: calcId,
        elementType: selectedElementType,
        elementGroup: elementText,
        surfaces: surfacesText,
        shape: selectedShape
      }
    };

    apiSecure(req_config)
      .then((response) => {
        const { RyhmaId } = response.data;
        refreshData(RyhmaId)
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Error:", error);
        setIsLoading(false);
      })
  };


  useEffect(() => {
    if (isOpen) {
      if (allTypes.length < 1) {
        getElementTypes()
      }
    }
  }, [isOpen]);

  return (
    <>
      <Modal
        open={isOpen}
        onCancel={() => closeModal()}
        title={`Lisää elementtiryhmä`}
        centered={true}
        footer={[
          <Button key="submit" type="primary" color="red" onClick={() => {
            saveData()
          }}
            loading={isLoading}>
            Tallenna
          </Button>,
        ]}
      >
        <div>
          <p className="project-modal-label font-dm-medium">{"Elementtityyppi"}</p>
          <Select
            showSearch
            size="large"
            style={{ width: '70%' }}
            placeholder="Valitse elementtityyppi"
            optionFilterProp="label"
            onChange={(value) => {
              console.log(`selected ${value}`);
              setSelectedElementType(value)
            }}
            options={allTypes}
            status={!selectedElementType ? 'error' : ''}
          />

          <ModalTextItem
            value={elementText}
            setValue={setElementText}
            label={"Elementtiryhmä"}
            disabled={!selectedElementType}
            maxLength={99}
          />

          <ModalTextItem
            value={surfacesText}
            setValue={setSurfacesText}
            label={"Pinnat"}
            disabled={!selectedElementType}
            maxLength={99}
          />


          {selectedElementTable?.table === 11 ? <>
            <p className="project-modal-label font-dm-medium">{"Oletusmuoto"}</p>
            <Select
              showSearch
              size="large"
              style={{ width: '70%' }}
              placeholder="Valitse muoto"
              optionFilterProp="label"
              onChange={(value) => {
                console.log(`selected ${value}`);
                setSelectedShape(value)
              }}
              options={allShapes}
              status={!selectedElementType ? 'error' : ''}
            />
          </> : <></>}

        </div>
      </Modal>
    </>
  );
};
