import { useEffect, useState } from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import { Login } from "../Login/Login";
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Loading } from "../../components/Loading/Loading";


import AllActions from "../../store/actions";
import { PdfViewer } from "../PdfViewer/PdfViewer";
import { getEnvSettings } from "../../config/config";

import { Avatar, ConfigProvider, Dropdown, Menu } from "antd";
import { Schedule } from "../Schedule/Schedule";
import { Project } from "./../Project/Project";
import { Projects } from "./../Projects/Projects";
import { MapPage } from "../MapPage/MapPage";
import { Header } from "../../components/Header/Header";

import antdLocale from 'antd/locale/fi_FI';

import dayjs from 'dayjs';
import 'dayjs/locale/fi';
import { Searchbar } from "../../components/Searchbar/Searchbar";
import { Contact } from "../Contact/Contact";
import { ProjectNote } from "../Project/ProjectNote/ProjectNote";
import { ProjectNotes } from "../Project/ProjectNote/ProjectNotes";
import { ProjectInfo } from "../Project/ProjectInfo/ProjectInfo";
import { ProjectElementsRoot } from "../Project/ProjectElementsRoot";
/* import { Quotation } from "../Quotation/Quotation"; */
import { useAxiosInterceptor } from "../../components/api";
import { Quotation } from "../Quotation/Quotation";
import { QuotationCalculation } from "../Quotation/QuotationCalculation";
import { ExcelPage } from "../Quotation/ExcelPage/ExcelPage";
import { QuotationGroupCalculation } from "../Quotation/QuotationGroupCalculation";
dayjs.locale('fi');


function App() {
  useAxiosInterceptor()

  const dispatch = useDispatch();
  const envConfig = getEnvSettings();

  const { userLoaded, loggedIn, showSearch } = useSelector((state) => state.currentUser);

  useEffect(() => {
    document.title = `YBT Oy - ${envConfig.title}`; // Quick solution
  }, []);

  //Käytetään siihen että infonäytön backend tunnistatuu accesstokenilla jotta voi ottaa screenshotteja aikataulusta.
  useEffect(() => {
    const updAuth = async (text) => {
      await localStorage.setItem(
        "USER_AUTH",
        JSON.stringify({
          accessToken: text,
          refreshToken: null,
          authenticated: true,
        })
      );
    };
    const urlParams = new URLSearchParams(window.location.search);
    let authParam = urlParams.get("auth");
    if (authParam) {
      updAuth(authParam);
    }

    dispatch(AllActions.userActions.loadUser());
  }, []);



  // Kuunnellaan näppäimistön painalluksia
  useEffect(() => {
    if (envConfig.env !== "intra") return

    const handleKeyDown = (event) => {
      // Tarkistetaan onko Ctrl + F painettu
      if ((event.ctrlKey || event.metaKey) && event.key === 'f') {
        event.preventDefault(); // Estää selaimen oman haun
        dispatch(AllActions.userActions.updateShowSearch(showSearch));
      }

      // Tarkistetaan onko Esc painettu
      if (event.key === 'Escape' || event.keyCode === 27) {
        // Suoritetaan haluttu toiminto, kun Esc painetaan
        dispatch(AllActions.userActions.updateShowSearch(true)); //Sulkee haun
      }
    };

    // Lisää keydown-tapahtuman kuuntelija
    window.addEventListener('keydown', handleKeyDown);

    // Siivoa kuuntelija komponentin poistuessa
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [showSearch]);




  // console.log("ROOTNAV:", userLoaded, loggedIn);
  if (!userLoaded) {
    return <Loading />;
  }

  if (!loggedIn) {
    return <Login />;
  }


  return (
    <div className="App">
      <ConfigProvider locale={antdLocale}>

        <BrowserRouter>
          <Header />
          <Searchbar />

          <Routes>
            <Route path="/" element={<Projects />} />
            {envConfig.showSchedule && <Route path="/schedule/" element={<Schedule />} />}
            {envConfig.showMap && <Route path="/map/" element={<MapPage />} />}
            {/* {envConfig.showMap && <Route path="/excel/" element={<ExcelPage />} />} */}

            <Route path="/projects/" element={<Projects />} />

            <Route path="/project/:id" element={<Project />} >
              <Route index element={<ProjectElementsRoot />} />
              {envConfig.env === "intra" && <Route path="notes" element={<ProjectNotes />} />}
              {envConfig.env === "intra" && <Route path="note/:noteId" element={<ProjectNote />} />}
              {envConfig.env === "intra" && <Route path="info" element={<ProjectInfo />} />}
            </Route>

            <Route path="/quotation/:projectId" element={<Quotation />} />
            <Route path="/quotation/:projectId/calculation/:calcId" element={<QuotationCalculation />} />
            <Route path="/quotation/:projectId/calculation/:calcId/group/:groupId" element={<ExcelPage />} />
            <Route path="/quotation/:projectId/calculation/:calcId/group/:groupId/calculation" element={<QuotationGroupCalculation />} />

            

        {/*     <Route path="/quotation/:id" element={<QuotationRoot />} >
              <Route index element={<Quotation />} />
                <Route path="calc/:calcId" element={<QuotationCalculation />} />
            </Route>
 */}


            {envConfig.env === "intra" && <Route path="/contact/:id" element={<Contact />} />}
            {envConfig.env === "intra" &&  <Route path="/pdfviewer/:id" element={<PdfViewer />} />}
            {/* <Route path="/quotation/:id" element={<Quotation />} /> */}

          </Routes>
        </BrowserRouter>
      </ConfigProvider>
    </div>
  );
}

export default App;
