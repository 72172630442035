import { CellWrapper } from "@silevis/reactgrid";

export const FooterCell = ({ value, style, suffix, round, conditionalRound, bottomLeft, bottomRight }) => {
  let nValue = value;
  if (round != null && !isNaN(value)) {
    nValue = value?.toFixed(round);
  }

  let formattedValue = nValue;
  if (conditionalRound && !isNaN(value)) {
    formattedValue = value % 1 === 0 ? value : value.toFixed(conditionalRound);
  }

  // Rakennetaan suffix JSX:ksi, jossa 2 tai 3 menee sup-muotoon
  const renderSuffix = () => {
    if (typeof suffix === "string") {
      return suffix.split("").map((char, index) =>
        (char === "2" || char === "3")
          ? <sup key={index}>{char}</sup>
          : <span key={index}>{char}</span>
      );
    }
    return suffix;
  };

  return (
    <CellWrapper
      onStringValueRequested={() => value?.toString() || ""}
      onStringValueReceived={() => { }}
      style={style}
      id={bottomLeft ? "bottomLeft" : bottomRight ? "bottomRight" : ""}
    >
      <div className="footer-text">
        {formattedValue} {renderSuffix()}
      </div>
    </CellWrapper>
  );
};