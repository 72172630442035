import { CloseOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";

import { Input, Modal, Button, Select } from "antd";
import dayjs from "dayjs";
import { useTranslate } from "../../../components/Translate";
import { apiSecure } from "../../../components/api";


/*
Muodot!
1=Neliö
2=Kolmio
3=Pyöreä
*/

//Lasketaan koko muodon mukaan
const calculateSize = (row) => {
  let nSize = 0

  if (row.shape === "1") {
    nSize = (row.width * row.height)
  } else if (row.shape === "2") {
    nSize = ((row.width * row.height) / 2); // Kolmion pinta-ala mm²
  } else if (row.shape === "3") {
    nSize = (Math.PI * Math.pow(row.width / 2, 2)); // Pyöreä: π * r²
  }

  return parseFloat((nSize / 1_000_000).toFixed(2)); // Pyöristys 2 desimaaliin
};

export const ElementHoleModal = ({ isOpen, closeModal, selectedHoleElement, saveFunc }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { locale } = useTranslate();

  const [rows, setRows] = useState([]);


  const totalAreaM2Calc = rows.reduce((sum, row) => {
    const sizeAsNumber = parseFloat(row.size) || 0;
    return sum + sizeAsNumber;
  }, 0);
  const totalAreaM2 = totalAreaM2Calc.toFixed(2)




  const addRow = () => {
    const newRow = { id: 'uusi' + rows.length + 1, shape: "1", width: 0, height: 0, size: 0 };
    setRows([...rows, newRow]);
  };

  const removeRow = (id) => {
    setRows(rows.filter(row => row.id !== id));
  };

  const updateRow = (id, field, value) => {
    setRows((prevRows) =>
      prevRows.map(row => {
        if (row.id !== id) return row;

        const updatedRow = { ...row, [field]: value };

        // Jos muoto on pyöreä (shape "3"), asetetaan height = 0
        if (field === "shape" && value === "3") {
          updatedRow.height = 0;
        }

        // Lasketaan uusi koko
        updatedRow.size = calculateSize(updatedRow);

        return updatedRow;
      })
    );
  };




  const getElementHoleData = async () => {
    setIsLoading(true);

    let req_config = {
      method: "POST",
      url: `intra_getElementHoleData`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        materialId: selectedHoleElement.id
      }
    };

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response);
        const { status, holes } = response.data
        console.log("RESPONSE", status, holes);

        if (status && holes) {
          holes.map((nHole) => {
            console.log('nHole', nHole)
          })
          const initialRows = holes.map((nHole) => {
            console.log('nHole', nHole);
            return {
              id: nHole.AukkoId,
              shape: nHole.Muoto.toString(),
              width: nHole.Pituus,
              height: nHole.Korkeus,
              size: nHole.Koko,
            };
          });

          setRows(initialRows)
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Error:", error);
        setIsLoading(false);
      })
  };

  useEffect(() => {
    if (isOpen) {
      getElementHoleData()
    } else {
      setRows([])
    }
  }, [selectedHoleElement]);

  const updateElementHoleData = () => {
    return new Promise((resolve, reject) => {
      setIsLoading(true);
  
      let req_config = {
        method: "POST",
        url: `intra_updateElementHoleData`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          elementId: selectedHoleElement.id,
          rows: rows
        }
      };
  
      apiSecure(req_config)
        .then((response) => {
          console.log("RESPONSE", response);
          setIsLoading(false);
          resolve(response);
        })
        .catch((error) => {
          console.log("Error:", error);
          setIsLoading(false);
          reject(error);
        });
    });
  };
  

  const saveBtnFunc = async () => {
    try {
      await updateElementHoleData();
      saveFunc(selectedHoleElement.id, totalAreaM2);
      closeModal();
    } catch (error) {
      console.error('Error updating element hole data:', error);
    }
  };


  return (
    <>
      <Modal
        open={isOpen}
        onCancel={() => closeModal()}
        title={`Aukot`}
        centered={true}
        width={"auto"}
        footer={[
          <Button key="submit" type="primary" color="red" onClick={() => {
            saveBtnFunc()
          }}
            loading={isLoading}
          >
            {locale.save}
          </Button>,
        ]}
      >
        <div>

          <div className="element-hole-table font-dm-regular">
            <table border="1" cellPadding="8" cellSpacing="0"
              style={{ width: "100%", textAlign: "center" }}>
              <thead>
                <tr>
                  <th style={{ width: "70px" }}>Muoto</th>
                  <th style={{ width: "100px" }}>Leveys (mm)</th>
                  <th style={{ width: "100px" }}>Korkeus (mm)</th>
                  <th style={{ width: "60px" }}>Koko m²</th>
                  <th style={{ width: "30px" }}></th>
                </tr>
              </thead>
              <tbody>
                {rows.map((row) => {

                  return (
                    <tr key={row.id}>
                      <td style={{ padding: 0, height: "100%" }}>
                        <select className="font-dm-regular" value={row.shape} onChange={(e) => updateRow(row.id, "shape", e.target.value)}>
                          <option value="1">Neliö</option>
                          <option value="2">Kolmio</option>
                          <option value="3">Pyöreä</option>
                        </select>
                      </td>
                      <td>
                        <input
                          className="font-dm-regular"
                          type="number"
                          min="0"
                          value={Number(row.width).toString()}
                          onChange={(e) => updateRow(row.id, "width", parseFloat(e.target.value) || 0)}
                        />
                      </td>
                      <td className={` ${row.shape === "3" ? 'disabled' : ''}`}>
                        {row.shape !== "3" ?
                          <input
                            className="font-dm-regular"
                            type="number"
                            min="0"
                            value={Number(row.height).toString()}
                            onChange={(e) => updateRow(row.id, "height", parseFloat(e.target.value) || 0)}
                          />
                          : <></>}
                      </td>
                      <td>
                        <span>{row?.size}</span>
                      </td>
                      <td>
                        <CloseOutlined onClick={() => removeRow(row.id)} />
                      </td>
                    </tr>
                  )
                })}

              </tbody>
              <tfoot>
                <tr className="footer-row">
                  <td colSpan="1">         <Button
                    type="primary"
                    onClick={addRow}
                    style={{ backgroundColor: '#CDCDCD', borderColor: '#CDCDCD', color: '#000' }}
                  >
                    Lisää rivi
                  </Button>
                  </td>

                  <td colSpan="2" style={{ textAlign: "right", fontWeight: "bold" }}>Yhteensä:</td>
                  <td>{totalAreaM2} m²</td>
                  <td></td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </Modal>
    </>
  );
};
