import { CloseOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";

import { Input, Modal, Button, DatePicker, Select, Checkbox, notification } from "antd";
import dayjs from "dayjs";
import { useTranslate } from "../../../components/Translate";
import { apiSecure } from "../../../components/api";
import { getEnvSettings } from "../../../config/config";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';




export const InfoModal = ({ isOpen, closeModal, defaultFormulas }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { locale } = useTranslate();
  const envConfig = getEnvSettings();

  console.log('defaultFormulas', defaultFormulas)

  return (
    <>
      <Modal
        open={isOpen}
        onCancel={() => closeModal()}
        title={`Kaavat`}
        centered={true}
        footer={[]}
      >
        <div>
          <div className="info-modal-formulas">
            {defaultFormulas.map((itm) => {
              return (<div>
                <p style={{ margin: 0 }}><span className="font-dm-medium">{itm.Koodi}</span> = {itm.Selite} ({itm.Kaava})</p>
              </div>)
            })}
          </div>
        </div>
      </Modal>
    </>
  );
};
