import { CellWrapper } from "@silevis/reactgrid";

export const NonEditCell = ({ value, style, suffix, round, bottomLeft, bottomRight }) => {
  let nValue = value
  if (round != null && !isNaN(value)) {
    nValue = value?.toFixed(round)
  }
  return (
    <CellWrapper onStringValueRequested={() => value?.toString() || ""} onStringValueReceived={() => { }} style={style} id={bottomLeft ? "bottomLeft" : bottomRight ? "bottomRight" : ""}>
      {nValue} {suffix}
    </CellWrapper>
  );
};