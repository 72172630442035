import { CloseOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";

import { Input, Modal, Button, DatePicker, Select } from "antd";
import dayjs from "dayjs";
import { useTranslate } from "../../components/Translate";
import { apiSecure } from "../../components/api";
import { getEnvSettings } from "../../config/config";
import { LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { replaceScandinavianCharacters } from "../../components/globalFunctions";

const { TextArea } = Input;



const ModalTextItem = ({ label, showLabel = true, placeholder, value, setValue, disabled = false, canLock = false, locked, setLocked, maxLength, status }) => {
  const { locale } = useTranslate();

  return (<div>
    {showLabel &&
      <p className="project-modal-label font-dm-medium">{label}</p>
    }
    <Input
      value={value}
      disabled={locked || disabled}
      placeholder={placeholder}
      onChange={(e) => {
        setValue(e.target.value)
      }}
      addonAfter={canLock ? <>{locked ? <LockOutlined onClick={() => { setLocked(false) }} /> : <UnlockOutlined onClick={() => { setLocked(true) }} />}</> : null}
      maxLength={maxLength}
      status={status}
    />
  </div>
  );
};

const formatNull = (data) => {
  return data ? data : null
};

export const AddQuotationReqModal = ({ isOpen, closeModal, contactId, refreshData, tarjousTehtaat }) => {
  console.log("AddQuotationReqModal data", tarjousTehtaat);

  const [isLoading, setIsLoading] = useState(false);

  const [name, setName] = useState(null);
  const [receiveDate, setReceiveDate] = useState(null);
  const [deadlineDate, setDeadlineDate] = useState(null);

  const [address1, setAddress1] = useState(null);
  const [address2, setAddress2] = useState(null);

  const [factoryArr, setFactoryArr] = useState([]);
  const [factorySelected, setFactorySelected] = useState(null);

  const [countrySelected, setCountrySelected] = useState(1);

  const [showGetCoords, setShowGetCoords] = useState(false);
  const [coordsLoading, setCoordsLoading] = useState(false);
  const [coordsError, setCoordsError] = useState(null);

  const { locale } = useTranslate();
  const envConfig = getEnvSettings();


  useEffect(() => {
    let nArr = tarjousTehtaat?.map(itm => ({
      label: itm.TehtaanNimi,
      value: itm.TehdasId
    }))

    if (nArr?.length >= 1) {
      setFactorySelected(nArr[0]?.value)
    }

    setFactoryArr(nArr)
  }, [tarjousTehtaat]);

  useEffect(() => {

    if (isOpen) {
      setName(null)
      setReceiveDate(dayjs().format("YYYY-MM-DD"))
      setDeadlineDate(null)
      setAddress1(null)
      setAddress2(null)
      setCountrySelected(1)
      setShowGetCoords(false)
      setCoordsLoading(false)
      setCoordsError(false)
    } else {
    }
  }, [isOpen]);

  useEffect(() => {
    if (address1 || address2) {
      setShowGetCoords(true)
    } else {
      setShowGetCoords(false)
    }
  }, [address1, address2]);


  // Koordinaattien haku
  const getLocation = () => {
    return new Promise(async (resolve, reject) => {
      console.log("getLocation kutsuttu");
      const factoryData = tarjousTehtaat.find(itm => itm.TehdasId === factorySelected);
      if (!factoryData?.TehdasId) {
        console.warn("Tehdasta ei löytynyt valitulla ID:llä");
        return reject("Tehdasta ei löytynyt");
      }

      setCoordsLoading(true);

      const tehdasOsoite = replaceScandinavianCharacters(`${factoryData.Lahiosoite} ${factoryData.Postiosoite}`);
      const kohdeOsoite = replaceScandinavianCharacters(`${address1} ${address2}`);

      console.log("Lähtöosoite:", tehdasOsoite);
      console.log("Kohdeosoite:", kohdeOsoite);

      if (!window.google || !window.google.maps) {
        console.error("Google Maps API ei ole ladattu!");
        setCoordsError("Google Maps API ei ole ladattu!");
        setCoordsLoading(false);
        return reject("Google Maps API ei ole ladattu");
      }

      try {
        const directionsService = new window.google.maps.DirectionsService();

        const getRoute = (request) => {
          return new Promise((resolve2, reject2) => {
            directionsService.route(request, (response, status) => {
              if (status === window.google.maps.DirectionsStatus.OK) {
                resolve2(response);
              } else {
                reject2(`Reitin haku epäonnistui: ${status}`);
              }
            });
          });
        };

        const request = {
          origin: tehdasOsoite,
          destination: kohdeOsoite,
          travelMode: window.google.maps.TravelMode.DRIVING,
        };

        const response = await getRoute(request);
        const leg = response?.routes[0]?.legs[0];
        if (!leg) {
          throw new Error("Reittiä ei löytynyt!");
        }

        const nRInfo = {
          distance: (leg.distance?.value / 1000 || 0).toFixed(0),
          start_address: leg.start_address,
          start_location: leg.start_location ? leg.start_location.toJSON() : null,
          end_address: leg.end_address,
          end_location: leg.end_location ? leg.end_location.toJSON() : null,
        };

        console.log("nRInfo", nRInfo);
        setCoordsError(null);
        resolve(nRInfo);
      } catch (error) {
        console.error("getLocation ERROR:", error);
        setCoordsError("Sijainnin haku epäonnistui!");
        reject("Sijainnin haku epäonnistui");
      } finally {
        setCoordsLoading(false);
      }
    });
  };

  const trySaveData = async () => {
    console.log('saveData');

    // Jos edes toinen toimitusosoitteista on määritetty, haetaan sijainti
    if (showGetCoords) {
      try {
        let locationData = await getLocation();
        console.log('locationData', locationData);

        Modal.confirm({
          title: 'Reitti löytyi',
          content: (
            <div>
              <p>
                <span style={{ fontWeight: 'bold' }}>{locationData.start_address}</span> -
                <span style={{ fontWeight: 'bold' }}>{locationData.end_address}</span>, jonne matkaa
                <span style={{ fontWeight: 'bold' }}> {locationData.distance}</span> km.
                Tallennetaanko näillä tiedoilla?
              </p>
              <div style={{ marginTop: 20 }}>
                <Button type="primary" onClick={() => window.open(`https://www.google.com/maps/dir/${locationData.start_address}/${locationData.end_address}`, "_blank")}>
                  Näytä reitti kartalla
                </Button>
              </div>
            </div>
          ),
          okText: 'Kyllä',
          cancelText: 'Ei',
          centered: true,
          onOk: () => submitData(locationData),
        });
      } catch (error) {
        console.error("saveData ERROR:", error);
        return;
      }
    } else {
      submitData()
    }
  };

  const submitData = async (locationData) => {
    setIsLoading(true)

    let req_config = {
      method: "POST",
      url: `${envConfig.apiUrlPrefix}addQuotationRequest`,
      data: {
        contactId: contactId,
        data: {
          name: formatNull(name),
          receiveDate: formatNull(receiveDate),
          deadlineDate: formatNull(deadlineDate),
          address1: formatNull(address1),
          address2: formatNull(address2),
          factorySelected: formatNull(factorySelected),
          countrySelected: formatNull(countrySelected),
          locationData: formatNull(locationData),
        },
      },
    };
    console.log("updateContact", req_config);

    apiSecure(req_config)
      .then((response) => {
        console.log("RESPONSE", response.data);
        closeModal()
        refreshData()
        setIsLoading(false)
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  }


  return (
    <>
      <Modal
        open={isOpen}
        onCancel={() => closeModal()}
        title={`Uusi tarjouspyyntö`}
        centered={true}
        footer={[
          <Button key="submit" type="primary" color="red"
            onClick={trySaveData}
            disabled={!name || !countrySelected || !factorySelected}
            loading={isLoading || coordsLoading}
          >
            {showGetCoords ? "Hae sijainti ja tallenna" : "Tallenna"}
          </Button>,
        ]}
      >
        <div>
          <ModalTextItem
            value={name}
            setValue={setName}
            label={"Työkohteen nimi"}
            status={!name && 'error'}
            maxLength={49}
          />

          <p className="project-modal-label font-dm-medium">Vastaanottoaika</p>
          <DatePicker
            format={'DD.MM.YYYY'}
            showWeek={true}
            className={`font-dm-regular`}
            allowClear={false}
            value={
              receiveDate ? dayjs(receiveDate) : null
            }
            onChange={(
              date
            ) => {
              let saveDate = dayjs(date).format("YYYY-MM-DD") //Tietokantaa varten tähän tmalliin
              setReceiveDate(saveDate)
            }}
          />


          <p className="project-modal-label font-dm-medium">Tarjouksen jättöpäivämäärä</p>
          <DatePicker
            format={'DD.MM.YYYY'}
            showWeek={true}
            className={`font-dm-regular`}
            allowClear={false}
            value={
              deadlineDate ? dayjs(deadlineDate) : null
            }
            onChange={(
              date
            ) => {
              let saveDate = dayjs(date).format("YYYY-MM-DD") //Tietokantaa varten tähän tmalliin
              setDeadlineDate(saveDate)
            }}
          />


          <ModalTextItem
            value={address1}
            setValue={setAddress1}
            label={"Toimitusosoite"}
            placeholder={"Lähiosoite"}
            maxLength={49}
          />

          <ModalTextItem
            value={address2}
            setValue={setAddress2}
            label={""}
            placeholder={"Postiosoite"}
            maxLength={49}
          />

          {coordsError ? <div><p style={{ color: "red" }}>
            {coordsError}
          </p></div> : <></>}

          <p className="project-modal-label font-dm-medium">Maa</p>
          <Select
            size="large"
            style={{ width: '70%' }}
            placeholder="Valitse maa"
            optionFilterProp="label"
            onChange={(value) => {
              console.log(`selected ${value}`);
              setCountrySelected(value)
            }}
            options={[
              { value: 1, label: 'Suomi' },
              { value: 2, label: 'Ruotsi' },
              { value: 3, label: 'Norja' }
            ]}
            value={countrySelected}
            status={!countrySelected && "error"}
          />

          <p className="project-modal-label font-dm-medium">Oletustehdas kuljetukselle</p>
          <Select
            size="large"
            style={{ width: '70%' }}
            placeholder="Valitse tehdas"
            optionFilterProp="label"
            onChange={(value) => {
              console.log(`selected ${value}`);
              setFactorySelected(value)
            }}
            options={factoryArr}
            value={factorySelected}
            status={!factorySelected && "error"}
          />
        </div>
      </Modal>
    </>
  );
};
